import https from "@/utils/https";
import { ContentType, Method } from "axios-mapper";

export const getAvailableJobs = () => {
  return https().request("/system-stats-recount-job/available-jobs", Method.GET);
};

export const getRecountJobs = (job) => {
  return https().request("/system-stats-recount-job/recount-jobs", Method.GET, job, ContentType.form);
};

export const addRecountJob = (job) => {
  return https().request("/system-stats-recount-job/recount-jobs", Method.POST, job, ContentType.form);
};
