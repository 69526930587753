import https from "@/utils/https";
import { ContentType, Method } from "axios-mapper";

export const getSettings = () => {
  return https().request("/setting", Method.GET);
};

export const updateSetting = (data) => {
  return https().request(`/setting/update?_method=PUT`, Method.POST, data, ContentType.form);
};
