<template>
  <div class="menu-main">
    <el-dialog
      title="Editor Setting"
      v-model="uiControl.dialogVisible"
      append-to-body
      width="600px"
      @close="uiControl.popoverVisible = false"
      destroy-on-close
    >
      <el-form
        ref="menuForm"
        :model="form"
        :rules="formRules"
        :inline="true"
        size="small"
        label-width="150px"
      >
        <el-form-item label="Name" prop="name">
          <el-input v-model="form.settingKey" style="width: 300px" readonly />
        </el-form-item>
        <el-form-item label="Value" prop="name">
          <el-switch
            v-if="fieldIsSwitch(form.settingKey)"
            v-model="form.settingValue"
            active-text="YES"
            inactive-text="NO"
            active-value="1"
            inactive-value="0"
          />
          <el-input v-else v-model="form.settingValue" placeholder="setting value" style="width: 300px" />
          <el-tooltip placement="top">
            <template #content>
              <strong>All parameter time unit is second</strong>
            </template>
            <el-icon style="padding-left: 10px;color: black">
              <Warning />
            </el-icon>
          </el-tooltip>
        </el-form-item>
      </el-form>
      <div class="dialog-footer">
        <el-button size="small" @click="resetForm">Cancel</el-button>
        <el-button size="small" @click="submit" type="primary">
          Confirm
        </el-button>
      </div>
    </el-dialog>

    <!-- eslint-disable -->
    <el-table
      :data="list"
      style="width: 100%"
      size="small"
      row-key="id"
      ref="treeTable"
      lazy
      :key="tableKey"
      :cell-class-name="cellGrayWhite"
    >
      <el-table-column
        prop="settingKey"
        label="Setting name"
        align="left"
      />
      <el-table-column prop="settingValue" label="Value">
        <template #default="scope">
          {{  settingValueDisplay(scope.row) }}
        </template>
      </el-table-column>
      <el-table-column label="operate" align="center">
        <template #default="scope">
          <el-button v-permission="['sys:setting:update']"
                     icon="el-icon-edit"
                     size="mini"
                     type="success"
                     @click="editMenu(scope.row)"
          />
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>

<script setup>
import { onMounted, reactive, ref } from 'vue'
import { required } from '../../../utils/validate'
import { ElMessage } from 'element-plus'
import {
  getSettings,
  updateSetting
} from '../../../api/settings'
import { cellGrayWhite } from '@/utils/style'

const uiControl = reactive({
  dialogVisible: false,
  popoverVisible: false,
  dialogTitle: '',
  dialogType: 'CREATE',
  switchSettings: ['SEND_MSG_BEFORE_MATCH', 'RATE_CHAT', 'IP_WHITELIST'],
})
const list = reactive([])
const form = reactive({
  settingKey: null,
  settingValue: null,
})
const formRules = reactive({
  settingKey: [required('key is require')],
  settingValue: [required('value is require')],
})
const menuForm = ref(null)

async function initList() {
  const { data: settings } = await getSettings()
  list.length = 0
  list.push(...settings)
}

function showDialog(type) {
  uiControl.dialogTitle = 'Edit Canned Message'
  uiControl.dialogType = type
  uiControl.dialogVisible = true
}

/**
 * 更新菜单
 * @param menu
 */
function editMenu(menu) {
  for (const key in menu) {
    if (Object.keys(form).find(k => k === key)) {
      form[key] = menu[key]
    }
  }
  showDialog('EDIT')
}

function submit() {
  menuForm.value.validate(async valid => {
    if (valid) {
      await updateSetting(form)
      await initList()
      uiControl.dialogVisible = false
      ElMessage({ message: 'Update Successful', type: 'success' })
    }
  })
}

function resetForm() {
  uiControl.dialogVisible = false
}

function fieldIsSwitch(key) {
  return uiControl.switchSettings.includes(key)
}

function settingValueDisplay(row) {
  if (fieldIsSwitch(row.settingKey)) {
    return row.settingValue === '1' ? 'YES' : 'NO'
  } else {
    return row.settingValue
  }
}

onMounted(async () => {
  await initList()
})
</script>

<style rel="stylesheet/scss" lang="scss" scoped>
.header-container {
  margin-bottom: 10px;
}

.search {
  display: flex;
  justify-content: flex-start;
}

.btn-group {
  margin-top: 15px;
}

.dialog-footer {
  display: flex;
  justify-content: flex-end;
}
</style>
