import https from "@/utils/https";
import { ContentType, Method } from "axios-mapper";

export const createSiteWelcomeMessage = (data) => {
  return https().request("/site-welcome-message", Method.POST, data, ContentType.form);
};

export const updateSiteWelcomeMessage = (data) => {
  return https().request(`/site-welcome-message/${data.id}?_method=PUT`, Method.POST, data, ContentType.form);
};

export const getSiteWelcomeMessage = () => {
  return https().request(`/site-welcome-message/getSiteWelcomeMessage`, Method.GET);
};
