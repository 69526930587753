<template>
  <div class="roles-main">
    <el-card class="card-chat">
      <template #header>
        <div class="card-header">
          <span>Transcript</span>
        </div>
      </template>
      <div style="max-height:500px; font-size:15px;overflow-y: scroll">
        <el-row>
          <div
            v-for="(message, index) in messageHistory"
            :key="`message-${index}`"
            class="message"
          >
            <div class="message-meta">
              [
              <span
                v-formatter="{
                  data: message.ts,
                  formatter: 'HH:mm:ss',
                  type: 'date',
                }"
                class="message-datetime"
              />
              ]
              <b>{{ sayerName(message) }} :</b>
            </div>
            <div v-if="message.type === 'image'" style="padding-left: 4rem;">
              <el-image
                hide-on-click-modal
                style="width: 128px; height: 128px; border: 3px solid grey"
                :src="message.msg"
                :preview-src-list="[message.msg]"
                fit="cover"
              />
            </div>
            <div v-safe-html="message.msg" style="padding-left:4rem" v-else />
          </div>
          <div class="message">
            {{ chatEndSReason }} ( end at
            <span
              v-formatter="{
                data: form.finishTime,
                formatter: 'HH:mm A',
                type: 'date',
              }"
            />
            )
          </div>
        </el-row>
      </div>
    </el-card>
    <el-card class="card-style">
      <template #header>
        <div class="card-header">
          <span>Info</span>
        </div>
      </template>
      <div style="max-height:200px; font-size:15px;overflow-y: scroll">
        <el-descriptions :column="2" size="small" align="left">
          <el-descriptions-item label="Guest Name :">
            {{ form.guestName }}
          </el-descriptions-item>
          <el-descriptions-item label="Agent Name :">
            {{ form.agentNickNameList.join() }}
          </el-descriptions-item>
          <el-descriptions-item label="ip :">
            {{ form.guestIp }}
          </el-descriptions-item>
          <el-descriptions-item label="Wrap Up :">
            {{ form.wrapUpChain }}
          </el-descriptions-item>
          <el-descriptions-item label="Browser :">
            {{ form.browser }}
          </el-descriptions-item>
          <el-descriptions-item label="Device :">
            {{ form.device }}
          </el-descriptions-item>
          <el-descriptions-item label="From :">
            {{ form.fromPage }}
          </el-descriptions-item>
          <el-descriptions-item label="Country :">
            {{ form.country }}
          </el-descriptions-item>
          <el-descriptions-item label="Province :">
            {{ form.province }}
          </el-descriptions-item>
          <el-descriptions-item label="Status :">
            {{ form.status }}
          </el-descriptions-item>
          <el-descriptions-item label="Description :">
            {{ form.description }}
          </el-descriptions-item>
          <el-descriptions-item label="Finish Type :">
            {{ getFinishReason(form.finishType) }}
          </el-descriptions-item>
          <el-descriptions-item label="Create Time :">
            <span
              v-formatter="{
                data: form.createTime,
                formatter: 'YYYY/MM/DD HH:mm:ss',
                type: 'date',
              }"
            />
          </el-descriptions-item>
          <el-descriptions-item label="Finish Time :">
            <span
              v-formatter="{
                data: form.finishTime,
                formatter: 'YYYY/MM/DD HH:mm:ss',
                type: 'date',
              }"
            />
          </el-descriptions-item>
          <el-descriptions-item label="Match Time :">
            <span
              v-formatter="{
                data: form.matchTime,
                formatter: 'YYYY/MM/DD HH:mm:ss',
                type: 'date',
              }"
            />
          </el-descriptions-item>
          <el-descriptions-item label="Close Time :">
            <span
              v-formatter="{
                data: form.closeTime,
                formatter: 'YYYY/MM/DD HH:mm:ss',
                type: 'date',
              }"
            />
          </el-descriptions-item>
          <el-descriptions-item label="Guest Id :">
            {{ form.roomName }}
          </el-descriptions-item>
          <el-descriptions-item label="Guest Rating :">
            <star-rating v-model:rating="form.guestRating" :read-only="true" star-size="10" inline="true"
                         :show-rating="false"
            />
          </el-descriptions-item>
          <el-descriptions-item label="Guest Comment :">
            <div style="word-break: break-word">{{ form.guestComment }}</div>
          </el-descriptions-item>
        </el-descriptions>
      </div>
    </el-card>
  </div>
</template>

<script setup>
import { nextTick, onMounted, reactive, computed } from '@vue/runtime-core'
import { getSiteRoomId, getFinishReason } from '../../../../api/room'
import { fetchWrapUp } from '../../../../api/wrap-up'
import { isEmpty, startsWith, map } from 'lodash'
import { defineProps } from 'vue'
import StarRating from 'vue-star-rating'

const props = defineProps({
  roomId: {
    type: Number,
    default: () => 0,
  },
})

const form = reactive({
  id: null,
  siteId: null,
  roomName: null,
  guestName: null,
  wrapUpChain: null,
  browser: null,
  device: null,
  fromPage: null,
  country: null,
  province: null,
  status: null,
  description: null,
  guestIp: null,
  createTime: null,
  matchTime: null,
  finishTime: null,
  closeTime: null,
  finishType: null,
  guestRating: null,
  guestComment: null,
  agentNickNameList: [],
  message: [],
})

const wrapUpList = []

async function loadForm(id) {
  const { data: ret } = await getSiteRoomId(id)
  nextTick(() => {
    for (const key in ret) {
      if (Object.keys(form).find(k => k === key)) {
        form[key] = ret[key]
      }
    }

    form.wrapUpChain = processWrapUpList(form.wrapUpChain)
  })
}

function sayerName(message) {
  const name = message.senderName ?? 'sys'
  if (startsWith(message.senderId, 'gt_')) {
    return `(guest) ${name}`
  } else {
    return `(cs) ${name}`
  }
}

function processWrapUpList(list) {
  let wrapUpStr = ''
  if (list !== null) {
    const resultList = []
    list.forEach(chain => {
      const chainStr = processWrapUpChain(chain)
      if (chainStr !== '') {
        resultList.push(chainStr)
      }
    })
    wrapUpStr = resultList.join(',')
  }
  return wrapUpStr;
}

function processWrapUpChain(chain) {
  let wrapUpStr = ''
  if (chain !== null) {
    const wrapUpArr = chain.split(',')
    const resultList = []
    wrapUpArr.forEach(element => {
      const existWrapUpList = wrapUpList.value
      const res = existWrapUpList.find(x => x.id === Number(element))
      if (res != null) {
        resultList.push(res.name)
      }
    })

    wrapUpStr = resultList.join('->')
  }
  return wrapUpStr
}

async function loadWrapUp() {
  const { data: ret } = await fetchWrapUp()
  wrapUpList.value = ret
}

onMounted(async () => {
  await loadWrapUp()
  loadForm(props.roomId)
})

const messageHistory = computed(() => {
  // debugger;
  if (isEmpty(form.message)) {
    return []
  }

  return map(form.message, item => {
    item = item.body
    item.sent = 1

    if (startsWith(item.senderId, 'gt_')) {
      item.sent = 0
    }

    if (item.type === 'options') {
      item.type = 'text'
      item.msg = '[options]'
    }

    if (item.type === 'option_selected') {
      item.type = 'text'
      if (item.meta.items && item.meta.items.length) {
        item.msg = item.meta.items[0]?.name
      }
    }

    if (item.type === 'file') {
      item.msg = ''

      if (item.files && item.files[0]) {
        const file = item.files[0]

        if (
          ['png', 'jpg', 'jpeg', 'webp', 'svg', 'gif'].includes(file.extension)
        ) {
          item.type = 'image'
          item.msg = file.url
        } else {
          item.msg = `<a href="${file.url}" target="_blank">File</a>`
        }
      }

      /*
      item.msg += map(item.files, (file, index) => {
        if (
          ['png', 'jpg', 'jpeg', 'webp', 'svg', 'gif'].includes(file.extension)
        ) {
          return `<a href="${file.url}" target="_blank"><img src="${file.url}" class="message-image" /></a>`
        }

        return `<a href="${file.url}" target="_blank">File ${index + 1}</a>`
      }).join(',')
      */
    }

    return item
  })
})

const chatEndSReason = computed(() => {
  return getFinishReason(form.finishType);
})
</script>

<style>
.card-style {
  margin-bottom: 10px;
  height: 500px;
}

.card-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.card-chat {
  min-height: 350px;
  max-height: 600px;
  margin-bottom: 10px;
}

.message {
  width: 100%;
  margin-bottom: 0.75rem;
}

.message-meta {
  margin-bottom: 0.25rem;
}

.message-datetime {
  color: grey;
}

.message-image {
  max-width: 128px;
}

.comment {
  overflow: auto;
}
</style>
