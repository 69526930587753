import { ContentType, Method } from "axios-mapper";
import https from "../utils/https";

export const QuestionType = {
  TEXT: "TEXT", API: "API", LINK: "LINK", MENU: "MENU"
}

// export const MenuViewScope = {
//   ALL: "ALL", ADMIN_ONLY: "ADMIN_ONLY"
// }

const filterNull = (form) => {
  const data = {};
  Object.entries(form).forEach(([key, value]) => {
    if (value !== null && value !== "") {
      data[key] = value;
    }
  });
  return data;
};

export const fetchQuestion = (request) => {
  return https().request("/site-questions", Method.GET, request);
};

export const fetchSimpleQuestion = (id, siteId) => {
  return https().request("/site-questions/simple", Method.GET, { id: id, siteId: siteId });
};

export const fetchQuestionName = (parentId) => {
  return https().request(`/site-questions/${parentId}`, Method.GET);
};

export const createQuestion = (menu) => {
  const data = filterNull(menu);
  return https().request(`/site-questions`, Method.POST, data, ContentType.form);
};

export const updateQuestion = (menu) => {
  const data = filterNull(menu);
  return https().request(`/site-questions/${menu.id}?_method=PUT`, Method.POST, data, ContentType.form);
};

export const deleteQuestion = (menuId) => {
  return https().request(`/site-questions/${menuId}?_method=DELETE`, Method.POST);
};

export const fetchQuestionChildren = (menuId) => {
  return https().request(`/site-questions/${menuId}/child-ids`, Method.GET);
};
