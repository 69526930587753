import https from "@/utils/https";
import { ContentType, Method } from "axios-mapper";

export const createSiteWelcome = (data) => {
  return https().request("/site-welcome", Method.POST, data, ContentType.form);
};

export const updateSiteWelcome = (data) => {
  return https().request(`/site-welcome/${data.id}?_method=PUT`, Method.POST, data, ContentType.form);
};

export const getSiteWelcome = () => {
  return https().request(`/site-welcome/getSiteWelcome`, Method.GET);
};
