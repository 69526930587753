<template>
  <div class="roles-main">
    <div class="header-container">
      <div class="search">
        <el-date-picker
          v-model="startDate"
          type="date"
          placeholder="Start Date"
          format="YYYY/MM/DD"
          value-format="YYYY-MM-DD"
          size="small"
        />
        <el-date-picker
          style="margin-left: 20px"
          v-model="endDate"
          type="date"
          placeholder="End Date"
          format="YYYY/MM/DD"
          value-format="YYYY-MM-DD"
          size="small"
        />
        <el-button
          style="margin-left: 20px"
          icon="el-icon-search"
          size="mini"
          type="success"
          @click="getRoomStatistics()"
        >
          Search
        </el-button>
        <el-select
          clearable
          v-model="statisticsType"
          size="small"
          placeholder="user state"
          class="filter-item"
          style="width: 120px; margin-left: 20px"
          @change="getRoomStatistics()"
        >
          <el-option
            v-for="item in uiControl.statisticsType"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          />
        </el-select>
        <h6 style="float: right">Total Rooms : {{ totalCount }}</h6>
      </div>
    </div>
    <div class="chart-time" v-if="list.records.length > 0">
      <Chart :options="options" />
    </div>
    <el-table
      :data="list.records"
      ref="table"
      row-key="id"
      size="small"
      highlight-current-row
      :cell-class-name="cellGrayWhite"
    >
      <el-table-column label="Time">
        <template #default="scope">
          <span v-if="statisticsType === statsType.DAY">
            {{ convertDate(scope.row.recordTime) }}
          </span>
          <span
            v-else
            v-formatter="{
              data: scope.row.recordTime,
              formatter: 'YYYY/MM/DD HH:mm:ss',
              type: 'date',
            }"
          />
        </template>
      </el-table-column>
      <el-table-column prop="totalCount" label="Rooms" />
      <el-table-column prop="missCount" label="Miss Room" />
      <el-table-column label="Avg. Wait Time">
        <template #default="scope">
          <span>{{ showDateTime(scope.row.avgWaitMatchTime) }}</span>
        </template>
      </el-table-column>
      <el-table-column label="Avg. Chat Time">
        <template #default="scope">
          <span>{{ showDateTime(scope.row.avgFinishTime) }}</span>
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>

<script setup>
import { onMounted, reactive, ref } from '@vue/runtime-core'
import { getRoomsStatisticsByTime, statsType } from '../../../../api/report'
import { showDateTime, convertDate, convertDateTime } from '@/utils/util'
import Chart from '@/components/charts/Charts'
import 'echarts/lib/component/tooltip'
import { cellGrayWhite } from '@/utils/style'

const list = reactive({
  records: [],
})

const startDate = ref(convertDate(new Date()))
const endDate = ref(convertDate(new Date()))
const statisticsType = ref('DAY')

const totalCount = ref(0)
const uiControl = reactive({
  statisticsType: [
    {
      value: statsType.DAY,
      label: 'Day',
    },
    {
      value: statsType.HOUR,
      label: 'Hour',
    },
  ],
})

const options = reactive({
  series: [],
  tooltip: {
    trigger: 'axis',
  },
  legend: {
    data: [
      'Total Room',
      // 'Miss Room',
      'Avg.Wait Match Time',
      'Avg.Finish Match Time',
    ],
  },
  grid: {
    left: '3%',
    right: '4%',
    bottom: '3%',
    containLabel: true,
  },
  xAxis: {
    type: 'category',
    axisPointer: {
      type: 'shadow',
    },
    data: [],
  },
  yAxis: [
    {
      type: 'value',
      name: 'Count',
      alignTicks: true,
    },
    {
      type: 'value',
      name: 'Time (hrs)',
    },
  ],
})

async function getRoomStatistics() {
  const { data: res } = await getRoomsStatisticsByTime(
    startDate.value,
    endDate.value,
    statisticsType.value
  )
  list.records = res
  if (res.length > 0) {
    totalCount.value = res
      .map(stats => stats.totalCount)
      .reduce((acc, count) => acc + count)
    const categoryData = []
    const totalCountData = []
    const avgWaitMatchTimeData = []
    const avgFinishTimeData = []
    res.forEach((item, index) => {
      if (statisticsType.value === statsType.DAY) {
        categoryData.push(convertDate(item.recordTime))
      } else {
        categoryData.push(convertDateTime(item.recordTime))
      }
      totalCountData.push(item.totalCount)
      avgWaitMatchTimeData.push(item.avgWaitMatchTime)
      avgFinishTimeData.push(item.avgFinishTime)
    })
    options.xAxis.data = categoryData
    options.series = [
      {
        name: 'Total Room',
        type: 'bar',
        // stack: 'count',
        data: totalCountData,
      },
      {
        name: 'Avg.Wait Match Time',
        type: 'line',
        yAxisIndex: 1,
        data: avgWaitMatchTimeData,
        tooltip: {
          valueFormatter: function (value) {
            return showDateTime(value);
          }
        },
      },
      {
        name: 'Avg.Finish Match Time',
        type: 'line',
        yAxisIndex: 1,
        data: avgFinishTimeData,
        tooltip: {
          valueFormatter: function (value) {
            return showDateTime(value);
          }
        },
      },
    ]
  }
}

onMounted(async () => {
  await getRoomStatistics()
})
</script>

<style scoped>
.chart-time {
  /* width: 700px; */
  height: 400px;
  margin: auto;
}
</style>
