import https from "@/utils/https";
import { ContentType, Method } from "axios-mapper";

export const getQuartzJobs = (job) => {
  return https().request("/schedule/quartz-job", Method.GET, job, ContentType.form);
};

export const getServers = () => {
  return https().request("/schedule/server", Method.GET);
};

export const getSiteServers = (jobId) => {
  return https().request("/schedule/site-server", Method.GET, { jobId: jobId }, ContentType.form);
};

export const updateJobServer = (jobServer) => {
  return https().request("/schedule/quartz-job?_method=PUT", Method.POST, jobServer, ContentType.json);
};
