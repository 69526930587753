import https from "@/utils/https";
import { ContentType, Method } from "axios-mapper";
// import { cacher } from "@/utils/cache";

export const roomFinishType = {
  GUEST_LEAVE: "GUEST_LEAVE",
  NOT_MATCHED: "NOT_MATCHED",
  LONG_IDLE: "LONG_IDLE",
  CS_CLOSE: "CS_CLOSE",
  GUEST_CLOSE: "GUEST_CLOSE",
  ROBOT: "ROBOT"
}

export const roomFinishReason = [
  {
    value: roomFinishType.GUEST_LEAVE,
    label: 'No matching found & guest leave',
  },
  {
    value: roomFinishType.NOT_MATCHED,
    label: 'No matching found & long time no match',
  },
  {
    value: roomFinishType.LONG_IDLE,
    label: 'Room inactive more than  3 mins',
  },
  {
    value: roomFinishType.CS_CLOSE,
    label: 'Room closed by CS',
  },

  {
    value: roomFinishType.GUEST_CLOSE,
    label: 'Room closed by guest',
  },

  {
    value: roomFinishType.ROBOT,
    label: 'Q/A',
  },
];

export const getFinishReason = (finishType) => {
  const reason = roomFinishReason.filter(o => o.value === finishType)
  if (reason.length === 0) {
    return "UNKNOWN"
  } else {
    return reason[0].label;
  }
}
export const getSiteRoom = (query) => {
  const queryCopy = Object.assign({}, query);
  if (query.guestRatings) {
    queryCopy.guestRatings = query.guestRatings.join(",")
  }
  return https().request("/site-room", Method.GET, queryCopy, ContentType.form);
};

export const downloadRooms = (query) => {
  const queryCopy = Object.assign({}, query);
  if (query.guestRatings) {
    queryCopy.guestRatings = query.guestRatings.join(",")
  }
  return https().request("/site-room/download", Method.GET, queryCopy, ContentType.form);
};

export const getSiteRoomId = (id) => {
  return https().request(`/cache/site-room/${id}`, Method.GET);
};

export const getAgents = (query) => {
  return https().request("/cache/site-room/agents", Method.GET, query, ContentType.form);
};
