<template>
  <div class="menu-main">
    <div class="header-container">
      <div class="btn-group" v-permission="['sys:question:create']">
        <el-button
          icon="el-icon-plus"
          size="mini"
          type="primary"
          @click="showDialog('CREATE')"
        >
          Add
        </el-button>
      </div>
    </div>
    <el-dialog
      :title="uiControl.dialogTitle"
      v-model="uiControl.dialogVisible"
      append-to-body
      width="650px"
      @close="uiControl.popoverVisible = false"
      destroy-on-close
    >
      <el-form
        ref="menuForm"
        :model="form"
        :rules="formRules"
        :inline="true"
        size="small"
        label-width="130px"
        style="margin: 0px 10px 0px 10px"
      >
        <el-form-item label="Site" prop="siteId" v-if="hasRole(['ADMIN'])">
          <el-select
            v-model="form.siteId"
            size="small"
            placeholder="Site"
            style="width: 394px"
            @change="updateSiteId()"
            :disabled="true"
          >
            <el-option
              v-for="item in userSites"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            />
          </el-select>
        </el-form-item>
        <el-form-item label="Question Type" prop="type">
          <el-radio-group v-model="form.type" size="mini" style="width: 500px" @change="changeType">
            <el-radio-button label="TEXT">Text</el-radio-button>
            <el-radio-button label="API">Api</el-radio-button>
            <el-radio-button label="LINK">Link</el-radio-button>
            <el-radio-button label="MENU">Menu</el-radio-button>
            <el-radio-button label="NO_ACTION">No Action</el-radio-button>
            <el-radio-button label="MANUAL_CS">Manual CS</el-radio-button>
            <el-radio-button label="LOGIN_PASSWORD">LOGIN_PASSWORD</el-radio-button>
            <el-radio-button label="LOGIN_PAGE">LOGIN_PAGE</el-radio-button>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="Name" prop="name">
          <el-input
            v-model="form.name"
            placeholder="Question name"
            style="width: 354px"
          />
          <el-popover
            :visible="uiControl.popoverVisible"
            placement="bottom-start"
            :width="300"
            @show="$refs['iconSelect'].reset()"
          >
            <EmojiPicker ref="iconSelect" @selected="selected" />
            <template #reference>
              <el-button
                size="small"
                circle
                @click="
                  uiControl.popoverVisible === true
                    ? (uiControl.popoverVisible = false)
                    : (uiControl.popoverVisible = true)
                "
                style="
                  height: 30px;
                  width: 31px;
                  float: right;
                  margin-left: 10px;
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  padding-top: 10px;
                "
              >
                <template #prefix>
                  <svg-icon
                    v-if="form.icon"
                    :icon-class="form.icon"
                    class="el-input_icon"
                    style="height: 32px; width: 16px"
                  />
                  <i v-else class="el-icon-search el-input__icon" />
                </template>
                <el-icon color="#000">
                  <Icon :icon="smileyIcon" />
                </el-icon>
              </el-button>
            </template>
          </el-popover>
        </el-form-item>

        <el-form-item
          v-show="form.type.toString() === 'TEXT'"
          label="Content"
          prop="content"
          style="width: 500px"
        >
          <div v-show="form.type.toString() === 'TEXT'">
            <!-- replace the api key -->
            <editor
              api-key="arlf5h2wcrqydmiiogww80cfx98khxd8o81s2i93qhq0n7wv"
              :init="{
                height: 250,
                width: 394,
                menubar: false,
                plugins: [
                  'advlist autolink lists link image charmap print preview anchor',
                  'searchreplace visualblocks code fullscreen',
                  'insertdatetime media table paste code help wordcount',
                ],
                toolbar:
                  'formatselect | bold italic underline backcolor |  undo redo | removeformat |\
                 alignleft aligncenter alignright alignjustify | \
                 bullist numlist outdent indent | link | code |  help',
                toolbar_mode: 'floating',
              }"
              v-model="form.content"
            />
          </div>
        </el-form-item>
        <el-form-item
          v-show="form.type.toString() === QuestionType.API"
          label="Api"
          prop="apiId"
        >
          <el-select
            v-model="form.apiId"
            size="small"
            placeholder="Api"
            style="width: 394px"
          >
            <el-option
              v-for="item in apis.list"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            />
          </el-select>
        </el-form-item>
        <el-form-item
          v-show="form.type.toString() === QuestionType.LINK"
          label="Link"
          prop="link"
        >
          <el-input
            v-model="form.link"
            placeholder="link"
            style="width: 394px"
          />
        </el-form-item>
        <el-form-item
          v-show="form.type.toString() === 'MENU'"
          label="Description"
          prop="description"
          style="width: 500px"
        >
          <div id="app" v-show="form.type.toString() === QuestionType.MENU">
            <!-- replace the api key -->
            <editor
              api-key="arlf5h2wcrqydmiiogww80cfx98khxd8o81s2i93qhq0n7wv"
              :init="{
                height: 250,
                width: 394,
                menubar: false,
                plugins: [
                  'advlist autolink lists link image charmap print preview anchor',
                  'searchreplace visualblocks code fullscreen',
                  'insertdatetime media table paste code help wordcount',
                ],
                toolbar:
                  'formatselect | bold italic underline backcolor |  undo redo | removeformat |\
                 alignleft aligncenter alignright alignjustify | \
                 bullist numlist outdent indent | link | code |  help',
                toolbar_mode: 'floating',
              }"
              v-model="form.description"
            />
          </div>
        </el-form-item>
        <el-form-item label="Superior Category" prop="parentId">
          <TreeSelect
            style="width: 394px"
            placeholder="please select superior category"
            @selected="selectTreeNode"
            :view-val="form.parentId"
            :siteId="treeSiteId"
            :key="reloadTreeComp"
          />
        </el-form-item>
        <el-form-item label="Status" prop="status">
          <el-radio-group
            v-model="form.status"
            size="mini"
            style="width: 300px"
          >
            <el-radio-button label="OPEN">Open</el-radio-button>
            <el-radio-button label="CLOSE">Close</el-radio-button>
            <el-radio-button label="TEST">Test</el-radio-button>
          </el-radio-group>
        </el-form-item>
      </el-form>
      <div class="dialog-footer">
        <el-button size="small" @click="resetForm">Cancel</el-button>
        <el-button size="small" @click="submit" type="primary">
          Confirm
        </el-button>
      </div>
    </el-dialog>

    <!-- eslint-disable -->
    <el-table
      :data="data.list"
      style="width: 100%"
      size="small"
      row-key="id"
      ref="treeTable"
      lazy
      :key="tableKey"
      :load="load"
      :tree-props="{ children: 'children', hasChildren: 'hasChildren' }"
      :cell-class-name="cellGrayWhite"
      :expand-row-keys="expandKeys.list"
    >
      <el-table-column prop="name" label="Question Name" align="left"/>
      <el-table-column prop="status" label="Status" align="left">
        <template #default="scope">
          <el-tag v-if="scope.row.status === 'OPEN'" type="success">
            {{ scope.row.status }}
          </el-tag>
          <el-tag v-if="scope.row.status === 'CLOSE'" type="danger">
            {{ scope.row.status }}
          </el-tag>
          <el-tag v-if="scope.row.status === 'TEST'">
            {{ scope.row.status }}
          </el-tag>
        </template>
      </el-table-column>
      <el-table-column label="operate" align="center">
        <template #default="scope">
          <el-button v-permission="['sys:question:update']"
                     icon="el-icon-edit"
                     size="mini"
                     type="success"
                     @click="editMenu(scope.row)"
          />
          <el-button v-permission="['sys:question:delete']"
                     icon="el-icon-remove"
                     size="mini"
                     type="danger"
                     @click="removeMenu(scope.row)"
          />
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>

<script setup>
import { computed, onMounted, reactive, ref } from 'vue'
import EmojiPicker from '../../../components/emoji-picker'
import TreeSelect from './tree-select'
import { required } from '../../../utils/validate'
import { ElMessage, ElMessageBox } from 'element-plus'
import {
  createQuestion,
  deleteQuestion,
  fetchQuestion,
  QuestionType,
  updateQuestion,
} from '../../../api/site-question'
import { hasRole } from '../../../utils/util'
import { getSiteApiSimple } from '../../../api/site-api'
import { store } from '../../../store'
import Editor from '@tinymce/tinymce-vue'
import { Icon } from '@iconify/vue'
import smileyIcon from '@iconify-icons/codicon/smiley'
import { cellGrayWhite } from '@/utils/style'

const siteId = computed(() => {
  return store.state.user.siteId
})

const userSites = computed(() => {
  return store.state.user.sites
})

/* https://www.tiny.cloud/docs-4x/integrations/vue/
    https://www.tiny.cloud/docs/tinymce/6/vue-ref/
    https://github.com/tinymce/tinymce-vue#usage
    https://www.tiny.cloud/blog/tinymce-toolbar
 */
const editor = Editor

const uiControl = reactive({
  dialogVisible: false,
  popoverVisible: false,
  dialogTitle: '',
  dialogType: 'CREATE',
})
const data = reactive({
  list: [],
})
const form = reactive({
  id: null,
  name: '',
  siteId: null,
  parentId: null,
  type: QuestionType.TEXT,
  content: null,
  link: null,
  apiId: null,
  description: null,
  status: null,
})
const formRules = reactive({
  name: [required('name is require')],
  siteId: [required('site is require')],
  type: [required('type is require')],
  // content: [required('content is require')],
  // apiId: [required('api is require')],
})
const menuForm = ref(null)
const treeTable = ref(null)
const tableKey = ref(Math.random())
const expandKeys = reactive({
  list: [],
})

const apis = reactive({
  list: [],
})

const request = reactive({
  siteId: null,
})

const reloadTreeComp = ref(0)
const treeSiteId = ref(null)

function changeType(value) {
  if (value !== 'API') {
    form.apiId = null;
  }
}

async function loadApi() {
  const { data: api } = await getSiteApiSimple(form.siteId)
  apis.list = api
}

async function initList() {
  data.list = []
  const { data: menus } = await fetchQuestion(request)
  JSON.parse(JSON.stringify(menus)).map(item => {
    // !!! must set "hasChildren = null" to display default-expand properly
    item.hasChildren = null
    data.list.push(item)
    expandKeys.list.push(item.id + '')
  })
}

async function load(tree, treeNode, resolve) {
  // request.parentId = tree.id
  // const { data: children } = await fetchQuestion(request)
  resolve(tree.children)
}

function selectTreeNode(id) {
  form.parentId = id
}

function showDialog(type) {
  if (type === 'CREATE') {
    form.name = ''
    form.description = ''
    form.content = ''
    form.parentId = -1
    form.siteId = siteId.value
    treeSiteId.value = siteId.value
    form.status = 'OPEN'
    uiControl.dialogTitle = 'Add Question'
  } else {
    uiControl.dialogTitle = 'Edit Question'
    treeSiteId.value = siteId.value
  }
  uiControl.dialogType = type
  uiControl.dialogVisible = true
}

function selected(name) {
  form.name = form.name + name
  uiControl.popoverVisible = false
}

async function updateSiteId() {
  treeSiteId.value = form.siteId
  reloadTree()
}

function reloadTree() {
  reloadTreeComp.value++
}

/**
 * 刷新菜单table
 * @param menu
 */
function refresh() {
  request.parentId = null
  initList()
  tableKey.value = Math.random()
}

/**
 * 更新菜单
 * @param menu
 */
function editMenu(menu) {
  for (const key in menu) {
    if (Object.keys(form).find(k => k === key)) {
      form[key] = menu[key]
    }
  }
  showDialog('EDIT')
}

/**
 *  新增菜单
 */
function create() {
  menuForm.value.validate(async valid => {
    if (valid) {
      if (form.type === QuestionType.TEXT) {
        form.link = null;
      } else if (form.type === QuestionType.API) {
        form.content = null;
        form.link = null;
      } else if (form.type === QuestionType.MENU) {
        form.link = null;
      }
      await createQuestion(form)
      refresh(form)
      uiControl.dialogVisible = false
      ElMessage({ message: 'Create Successful', type: 'success' })
    }
  })
}

/**
 *  编辑菜单
 */
function edit() {
  menuForm.value.validate(async valid => {
    if (valid) {
      await updateQuestion(form)
      refresh(form)
      uiControl.dialogVisible = false
      ElMessage({ message: 'Update Successful', type: 'success' })
    }
  })
}

function submit() {
  if (uiControl.dialogType === 'CREATE') {
    create()
  } else {
    edit()
  }
}

/**
 * 删除菜单
 * @param menu
 */
function removeMenu(menu) {
  if (menu.hasChildren) {
    ElMessage({ message: 'This menu has child，Please delete child first', type: 'warning' })
  } else {
    ElMessageBox.confirm('Confirm the deletion', {
      confirmButtonText: 'Yes',
      cancelButtonText: 'No',
      type: 'warning',
    }).then(async () => {
      await deleteQuestion(menu.id)
      refresh(menu)
      ElMessage({ message: 'Delete Successful', type: 'success' })
    })
  }
}

function resetForm() {
  uiControl.dialogVisible = false
}

onMounted(async () => {
  await loadApi()
  await initList()
})
</script>

<style rel="stylesheet/scss" lang="scss" scoped>
.header-container {
  margin-bottom: 10px;
}

.btn-group {
  margin-top: 15px;
}

.dialog-footer {
  display: flex;
  justify-content: flex-end;
  margin: 0px 45px 0px 0px;
}
</style>
<style rel="stylesheet/scss">
.tox-tinymce-aux {
  z-index: 9999 !important;
}

.tox-notifications-container {
  display: none;
}
</style>
