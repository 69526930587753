import { useStore } from '../store'
import vueDefaultConfig from '../config/vue.custom.config'
import { ADMIN } from '../store/modules/user/action-types'
import moment from 'moment'
import * as XLSX from 'xlsx/xlsx.mjs'
import '../styles/el_table.css'
import escape from 'lodash/escape'
import ExcelJS from 'exceljs';
import FileSaver from 'file-saver';

export const hasPermission = permission => {
  const permissions = useStore().state.user.permissions
  if (useStore().state.user.userType === ADMIN.value) {
    return true
  } else {
    if (permission && permission instanceof Array) {
      if (permission.length > 0) {
        const permissionRoles = permission
        return permissions.some(role => {
          return permissionRoles.includes(role)
        })
      }
    }
  }
}

export const hasRole = roles => {
  const role = useStore().state.user.userType
  if (roles) {
    if (roles instanceof Array) {
      return roles.includes(role)
    } else {
      return roles === role
    }
  }
}

export const showTips = name => {
  const menus = vueDefaultConfig.showTipsMenu
  return menus.includes(name)
}

export const showDateTime = seconds => {
  let timeText = ''
  const hour = Number.parseInt(seconds / 3600)
  if (hour > 0) {
    timeText += hour + 'h'
  }
  const min = Number.parseInt((seconds % 3600) / 60)
  if (min > 0) {
    if (timeText !== '') {
      timeText += '.' + min + 'min'
    } else {
      timeText += min + 'min'
    }
  }

  if (timeText === '') {
    timeText = '0s'
  }
  return timeText
}

export const convertDate = (timeStr, formatter = 'YYYY-MM-DD') => {
  return moment(timeStr).format(formatter)
}

export const convertDateTime = (timeStr, formatter = 'YYYY-MM-DD HH:mm:ss') => {
  return moment(timeStr).format(formatter)
}

export const addDay = ({ date = new Date(), amount } = {}) => {
  return moment(date).add(amount, 'days')
}

export const escapeXSS = (msg) => {
  return escape(msg)
}

export const exportExl = (array, fileName, wscols) => {
  const book = XLSX.utils.book_new()
  const sheet = XLSX.utils.json_to_sheet(array, [])
  if (wscols) {
    sheet['!cols'] = wscols
  }
  XLSX.utils.book_append_sheet(book, sheet, 'Sheet1')
  XLSX.writeFile(book, fileName, { type: 'buffer', bookType: 'xls', bookSST: false })
}

export const exportExl2 = (array, fileName, cols) => {
  const workbook = new ExcelJS.Workbook();
  const worksheet = workbook.addWorksheet('Sheet1');

  array.forEach((data, index) => {
    const row = worksheet.getRow(index + 2);
    Object.values(data).forEach((value, columnIndex) => {
      row.getCell(columnIndex + 1).value = value;
    });
  });

  worksheet.columns = cols;

  workbook.xlsx.writeBuffer().then(buffer => {
    const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
    FileSaver.saveAs(blob, fileName);
  });
}

export const jsonToTable = data => {
  const col = []
  for (let i = 0; i < data.length; i++) {
    for (const key in data[i]) {
      if (col.indexOf(key) === -1) {
        col.push(key)
      }
    }
  }

  // Create table.
  const table = document.createElement('table')

  // Create table header row using the extracted headers above.
  const tr = table.insertRow(-1) // table row.

  for (let i = 0; i < col.length; i++) {
    const th = document.createElement('th') // table header.
    th.innerHTML = col[i]
    tr.appendChild(th)
  }

  // add json data to the table as rows.
  for (let i = 0; i < data.length; i++) {
    const tr = table.insertRow(-1)

    for (let j = 0; j < col.length; j++) {
      const tabCell = tr.insertCell(-1)
      tabCell.innerHTML = data[i][col[j]]
    }
  }

  return table
}
