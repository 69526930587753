<template>
  <div class="roles-main">
    <div class="header-container">
      <div class="search" v-if="userType !== 'TENANT'">
        <el-input
          v-model="request.name"
          size="small"
          style="width: 200px"
          placeholder="user name"
        />
        <el-button
          style="margin-left: 20px"
          icon="el-icon-search"
          size="mini"
          type="success"
          @click="loadUser"
        >
          Search
        </el-button>
        <el-button
          icon="el-icon-refresh"
          size="mini"
          type="warning"
          @click="resetQuery()"
        >
          reset
        </el-button>
      </div>
    </div>
    <el-dialog
      :title="uiControl.dialogTitle"
      v-model="uiControl.dialogVisible"
      append-to-body
      width="580px"
    >
      <el-form
        ref="userForm"
        :rules="formRules"
        :model="form"
        :inline="true"
        size="small"
        label-width="150px"
      >
        <el-form-item label="User Name" prop="loginName">
          <el-input
            :disabled="true"
            v-model="form.loginName"
            style="width: 350px"
          />
        </el-form-item>
        <el-form-item label="Nickname " prop="nickName">
          <el-input v-model="form.nickName" style="width: 350px" />
        </el-form-item>
        <el-form-item label="Profile Picture" prop="head">
          <div class="icon-body">
            <div class="headicon-box">
              <el-radio-group
                v-model="form.head"
                size="small"
                v-for="item in pageProfile.records"
                :key="item.id"
              >
                <el-radio-button :label="item.id">
                  <el-image class="headicon-img" :src="item.head" />
                </el-radio-button>

                <!-- <el-radio-button label="Washington"></el-radio-button>
          <el-radio-button label="Los Angeles"></el-radio-button>
          <el-radio-button label="Chicago"></el-radio-button> -->
              </el-radio-group>
            </div>
          </div>
          <el-pagination
            class="pagination"
            @current-change="profileChangePage"
            layout="prev, next"
            :page-size="requestProfile.size"
            :page-count="pageProfile.pages"
            :current-page="requestProfile.current"
          />
        </el-form-item>
        <div class="dialog-footer">
          <el-button @click="uiControl.dialogVisible = false">cancel</el-button>
          <el-button type="primary" @click="submit">confirm</el-button>
        </div>
      </el-form>
    </el-dialog>
    <el-table
      :data="page.records"
      ref="table"
      row-key="id"
      size="mini"
      highlight-current-row
      :cell-class-name="cellGrayWhite"
    >
      <el-table-column label="Site" :formatter="toSiteName" width="200" />
      <el-table-column prop="loginName" label="User Name" width="200">
        <template #default="scope">
          {{ scope.row.loginName }}
          <el-tag
            size="small"
            v-if="scope.row.loginName === LOGIN_USER_NAME"
            style="margin-left: 5px"
          >
            it's you
          </el-tag>
        </template>
      </el-table-column>
      <el-table-column prop="nickName" label="Nickname" width="200" />
      <el-table-column prop="headIcon" label="Head Icon">
        <template #default="scope">
          <el-avatar
            style="width: 40px; height: 40px"
            :src="scope.row.headIcon"
            shape="square"
          />
        </template>
      </el-table-column>
      <el-table-column label="operate" width="450" align="right">
        <template #default="scope">
          <el-button
            size="mini"
            type="success"
            icon="el-icon-user />"
            @click="showChangeProfilePicture(scope.row)"
          >
            Update Profile
          </el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      class="pagination"
      @current-change="changePage"
      layout="prev, pager, next"
      :page-size="request.size"
      :page-count="page.pages"
      :current-page="request.current"
    />
  </div>
</template>

<script setup>
import { computed, nextTick, onMounted, reactive, ref } from 'vue'
import { ElMessage } from 'element-plus'
import { getUsersProfilePic, updateUserProfilePicture } from '../../../api/user'
import { useStore } from '../../../store'
import { getSystemHeadIcon } from '../../../api/system-head-icon'
import { required } from '../../../utils/validate'
import { cellGrayWhite } from '@/utils/style'
import { getSiteListSimple } from '../../../api/site'

const store = useStore()
const LOGIN_USER_NAME = computed(() => store.state.user.name)

const siteList = reactive({
  list: [],
})

const userSiteId = computed(() => {
  return store.state.user.siteId
})

const userType = computed(() => {
  return store.state.user.userType
})

const userForm = ref(null)
const uiControl = reactive({
  dialogVisible: false,
  dialogTitle: '',
})
const page = reactive({
  pages: 0,
  records: [],
})

const pageProfile = reactive({
  pages: 0,
  records: [],
})

const request = reactive({
  size: 30,
  current: 1,
  name: null,
  enable: null,
  siteIds: ',' + userSiteId.value + ',',
})

const requestProfile = reactive({
  size: 20,
  current: 1,
})

const form = reactive({
  id: null,
  loginName: null,
  nickName: null,
  head: null,
  headIcon: null,
})

const formRules = reactive({
  head: [required('Icon is require')],
  nickName: [required('Nickname is require')],
})

function profileChangePage(page) {
  requestProfile.current = page
  loadSystemHeadIcon()
}

function resetQuery() {
  request.name = null
}

async function loadUser() {
  if (userType.value === 'TENANT') {
    request.name = LOGIN_USER_NAME
  }
  const { data: ret } = await getUsersProfilePic(request)
  page.pages = ret.pages
  page.records = ret.records
}

function changePage(page) {
  request.current = page
  loadUser()
}

function showChangeProfilePicture(user) {
  uiControl.dialogTitle = 'Update Profile Profile'
  uiControl.dialogVisible = true
  nextTick(() => {
    form.id = user.id
    form.loginName = user.loginName
    form.head = user.head
    form.nickName = user.nickName
  })
}

function updateProfilePicture() {
  userForm.value.validate(async valid => {
    if (valid) {
      await updateUserProfilePicture(form)
      uiControl.dialogVisible = false
      ElMessage({ message: 'Update Profile Picture Success', type: 'success' })
    }
    loadUser()
  })
}

function submit() {
  updateProfilePicture()
}

function toSiteName(row, column, cellValue, index) {
  let siteIdArr = []
  const siteStrList = []
  if (row.siteId) {
    siteIdArr = row.siteId.split(',')
    siteIdArr.forEach(element => {
      if (element !== '') {
        siteStrList.push(
          siteList.list.find(site => site.id === Number(element)).siteName
        )
      }
    })
    return siteStrList.join(' , ')
  } else {
    return '-'
  }
}

async function loadSystemHeadIcon() {
  const { data: ret } = await getSystemHeadIcon(requestProfile)
  pageProfile.pages = ret.pages
  pageProfile.records = ret.records
}

async function loadSites() {
  const { data: ret } = await getSiteListSimple()
  siteList.list = ret
}

onMounted(() => {
  loadSites()
  loadUser()
  loadSystemHeadIcon()
  console.log(userType.value)
})
</script>

<style rel="stylesheet/scss" lang="scss" scoped>
.header-container {
  margin-bottom: 10px;
}

.search {
  display: flex;
  justify-content: flex-start;
}

.btn-group {
  margin-top: 15px;
}

.dialog-footer {
  display: flex;
  justify-content: flex-end;
  margin-right: 38px;
}

// .pagination {
//   display: flex;
//   justify-content: flex-end;
//   margin-right: 20px;
// }

.el-table--enable-row-transition .el-table__body td.el-table__cell {
  padding: 4px 0;
}

.icon-body {
  width: 100%;

  .headicon-box {
    // overflow-y: scroll;
    display: flex;
    flex-wrap: wrap;
    gap: 10px;

    div {
      cursor: pointer;

    }
  }
}

.headicon-img {
  width: 60px;
  display: block;
}

:deep(.el-radio-button--small .el-radio-button__inner) {
  padding: 0;
}

:deep(.el-radio-button--small) {
  padding: 0;
  img{

    filter: grayscale(.7);
  }

  &.is-active {
    img {
      filter: grayscale(0);
    }
  }
}
</style>
