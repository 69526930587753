<template>
  <router-view />
  <RoomList v-if="name === 'Room'" />
  <!-- <RoomDetails v-else /> -->
</template>

<script>
import { computed, defineComponent } from '@vue/runtime-core'
import { useRouter } from 'vue-router'
// import RoomDetails from './details'
import RoomList from './list'
import { hasRole } from '../../../utils/util'

export default defineComponent({
  components: {
    // RoomDetails,
    RoomList,
  },
  setup() {
    const router = useRouter()
    const name = computed(() => {
      return router.currentRoute.value.name
    })

    const {
      params: { id },
    } = router.currentRoute.value
    return {
      id,
      name,
      hasRole,
    }
  },
})
</script>
