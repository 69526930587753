
const icons = [
  { name: "event", emoji: "📅" },
  { name: "promo", emoji: "💰" },
  { name: "bet", emoji: "🎰" },
  { name: "note", emoji: "📌" },
  { name: "search", emoji: "🔎" },
  { name: "gift", emoji: "🎁" },
  { name: "money", emoji: "💲" },
  { name: "annouce", emoji: "📢" },
  { name: "promo", emoji: "🎫" },
  { name: "jackpot", emoji: "🎰" },
  { name: "rmb", emoji: "💴" },
  { name: "em", emoji: "❗" },
  { name: "dollar", emoji: "💲" },
  { name: "man", emoji: "🙋" },
  { name: "fire", emoji: "🔥" },
  { name: "sparkles", emoji: "✨" },
  { name: "birthday", emoji: "🎂" },
  { name: "gift", emoji: "🎁" },
  { name: "phone", emoji: "📱" },

]
export default icons;
