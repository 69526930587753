<template>
  <el-select v-model="label" v-bind="$attrs" ref="select">
    <el-option value="">
      <el-tree
        ref="tree"
        lazy
        :expand-on-click-node="false"
        :props="treeProps"
        :load="loadTreeMenu"
        highlight-current
        @node-click="handleNodeClick"
      />
    </el-option>
  </el-select>
</template>

<script setup>
import { defineEmits, defineProps, onMounted, ref, watch } from 'vue'
import { fetchSimpleWrapUp, fetchWrapUpName } from '../../../../api/wrap-up'

const select = ref('')
const label = ref('')
const emit = defineEmits(['selected'])
const props = defineProps({
  treeProps: {
    type: Object,
    default: () => {
      return {
        id: 'id',
        label: 'name',
        children: 'children',
        isLeaf: 'isLeaf',
      }
    },
  },
  siteId: {
    type: Number,
    default: () => 0,
  },
  viewVal: {
    type: Number,
    default: () => -1,
  },
})

onMounted(async () => {
  if (props.viewVal !== -1) {
    const { data: menu } = await fetchWrapUpName(props.viewVal)
    label.value = menu
  } else {
    label.value = '-----Default Option-----'
  }
})

watch(
  () => props.viewVal,
  async () => {
    if (props.viewVal !== -1) {
      const { data: menu } = await fetchWrapUpName(props.viewVal)
      label.value = menu
    } else {
      label.value = '-----Default Option-----'
    }
  }
)

function handleNodeClick(node) {
  label.value = node[props.treeProps.label]
  emit('selected', node[props.treeProps.id])
  select.value.blur()
}

async function loadTreeMenu(treeNode, resolve) {
  const defaultElement = [
    {
      id: null,
      parentId: -1,
      siteId: props.siteId,
      name: '-----Default Option-----',
      children: [],
    },
  ]
  if (treeNode.level === 0) {
    const { data: children } = await fetchSimpleWrapUp(null, props.siteId)
    children.children = [];
    const newChildren = defaultElement.concat(children)
    resolve(newChildren)
  } else {
    resolve([])
  }
  // else {
  //   if (treeNode.data.id !== null) {
  //     const { data: children } = await fetchSimpleWrapUp(
  //       treeNode.data.id,
  //       props.siteId
  //     )
  //     resolve(children)
  //   } else {
  //     resolve([])
  //   }
  // }
}

// function treeChildren(data, id) {
//   console.log(data)
//   var result = []
//   var objArr = []
//   data.forEach(obj => {
//     if (obj.id === id) {
//       const childArray = obj.children
//       childArray.forEach(element => {
//         if (element.parentId === id) {
//           console.log('check children parent')
//           console.log(element)
//           objArr.push(element)
//         }
//       })
//       console.log(objArr)
//       result = objArr
//     } else {
//       console.log('checking children list')
//       treeChildren(obj.children, id)
//     }
//   })
//   console.log(result)
//   return result
// }
</script>
<style scoped>
.el-select-dropdown__item {
  height: 100%;
  padding: 10px 0;
}
</style>
