<template>
  <div class="roles-main">
    <div class="header-container">
      <div class="search">
        <el-input
          v-model="request.name"
          size="small"
          style="width: 200px"
          placeholder="user name"
        />
        <el-button
          style="margin-left: 20px"
          icon="el-icon-search"
          size="mini"
          type="success"
          @click="loadJobs"
        >
          Search
        </el-button>
        <el-button
          icon="el-icon-refresh"
          size="mini"
          type="warning"
          @click="resetQuery()"
        >
          reset
        </el-button>
      </div>
    </div>
    <el-dialog
      :title="uiControl.dialogTitle"
      v-model="uiControl.dialogVisible"
      append-to-body
      width="580px"
    >
      <el-form
        ref="userForm"
        :model="form"
        :rules="formRules"
        :inline="true"
        size="small"
        label-width="150px"
      >
        <el-form-item label="Job Name" prop="jobName">
          <el-input
            v-model="form.jobName"
            style="width: 350px"
          />
        </el-form-item>
        <el-form-item
          label="Bean Name"
          prop="beanName"
        >
          <el-input
            v-model="form.beanName"
            style="width: 350px"
          />
        </el-form-item>
        <el-form-item
          label="Cron Expression"
          prop="cronExpression"
        >
          <el-input
            v-model="form.cronExpression"
            style="width: 350px"
          />
        </el-form-item>
        <el-form-item
          label="Params"
          prop="params"
        >
          <el-input
            v-model="form.params"
            type="textarea"
            :rows="2"
            style="width: 350px"
          />
        </el-form-item>
        <el-form-item
          label="Select Sites"
        >
          <el-switch
            v-model="form.selectSites"
            :active-value="true"
            :inactive-value="false"
            @change="clearFormSites"
          />
        </el-form-item>
        <el-form-item
          label="Enable Recount"
        >
          <el-switch
            v-model="form.enableRecount"
            :active-value="true"
            :inactive-value="false"
          />
        </el-form-item>
        <el-form-item
          label="Description"
          prop="description"
        >
          <el-input
            v-model="form.description"
            style="width: 350px"
          />
        </el-form-item>
        <el-form-item
          label="Server Allocation"
        >
          <el-table
            :data="form.siteServers"
            ref="sub"
            row-key="serverCode"
            size="mini"
            style="width:350px"
            v-loading="!form.loadedServers"
          >
            <el-table-column prop="serverCode" label="Server Code" />
            <el-table-column label="Sites" v-if="form.selectSites">
              <template #default="scope">
                <el-select
                  v-model="scope.row.sites"
                  multiple
                  style="width: 100%"
                  :disabled="!form.selectSites"
                  @focus="loadAvailableSites(scope.row.serverCode)"
                >
                  <el-option
                    v-for="item in availableSiteList.list"
                    :key="item.id"
                    :label="item.siteName"
                    :value="item.id"
                  />
                </el-select>
              </template>
            </el-table-column>
            <el-table-column label="Run on server" v-if="!form.selectSites">
              <template #default="scope">
                <el-switch
                  :model-value="isRunningOnServer(scope.row.sites)"
                  :active-value="true"
                  :inactive-value="false"
                  @change="toggleJobRunOnServer(scope.row.serverCode)"
                />
              </template>
            </el-table-column>
          </el-table>
        </el-form-item>
        <el-form-item label="Job State">
          <el-switch
            v-model="form.state"
            :active-value="1"
            :inactive-value="0"
          />
        </el-form-item>
        <div class="dialog-footer">
          <el-button @click="uiControl.dialogVisible = false">cancel</el-button>
          <el-button type="primary" @click="submit">confirm</el-button>
        </div>
      </el-form>
    </el-dialog>
    <el-button
      icon="el-icon-plus"
      size="mini"
      type="primary"
      @click="showCreate"
    >
      Add
    </el-button>
    <el-table
      :data="page.records"
      ref="table"
      row-key="id"
      @expand-change="loadJobServers"
      size="mini"
      highlight-current-row
      :cell-class-name="cellGrayWhite"
    >
      <el-table-column type="expand">
        <template #default="scope">
          <el-table :data="scope.row.servers" v-loading="!scope.row.loadedServers" size="mini" row-key="id" style="margin-left: 5%">
            <el-table-column prop="serverCode" label="Server Code" />
            <el-table-column :formatter="getStatus" label="Server Status" />
            <el-table-column :formatter="toSiteName" label="Site" />
          </el-table>
        </template>
      </el-table-column>

      <el-table-column prop="jobName" label="Job Name" />
      <el-table-column prop="cronExpression" label="Cron" />
      <el-table-column prop="status" label="State">
        <template #default="scope">
          <el-switch
            :disabled="true"
            v-model="scope.row.state"
            :active-value="1"
            :inactive-value="0"
          />
        </template>
      </el-table-column>
      <el-table-column
        label="operate"
        width="200"
        align="right"
        v-if="hasPermission(['sys:user:update:auto-allocation'])"
      >
        <template #default="scope">
          <el-button
            size="mini"
            type="success"
            v-permission="['sys:user:update:auto-allocation']"
            @click="showEdit(scope.row)"
          >
            Update Job
          </el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      class="pagination"
      @current-change="changePage"
      layout="prev, pager, next"
      :page-size="request.size"
      :page-count="page.pages"
      :current-page="request.current"
    />
  </div>
</template>

<script setup>
import { nextTick, onMounted, reactive, ref } from 'vue'
import { required } from '../../../utils/validate'
import { ElMessage } from 'element-plus'
import {
  getQuartzJobs,
  getSiteServers,
  getServers,
  updateJobServer
} from '../../../api/schedule-job'
import { hasPermission } from '../../../utils/util'
import { cellGrayWhite } from '@/utils/style'
import { getSiteListSimple } from '../../../api/site'

const siteList = reactive({
  list: [],
})

const serverList = reactive({
  list: [],
})

const availableSiteList = reactive({
  list: [],
})

const userForm = ref(null)
const uiControl = reactive({
  dialogVisible: false,
  dialogTitle: '',
  dialogType: 'EDIT',
  // editBtn: true,
})
const page = reactive({
  pages: 0,
  records: [],
})
const request = reactive({
  size: 30,
  current: 1,
  jobName: null,
})

const form = reactive({
  id: null,
  jobName: null,
  beanName: null,
  params: null,
  cronExpression: null,
  description: null,
  selectSites: false,
  enableRecount: false,
  siteServers: [],
  state: null,
})

const formRules = reactive({
  jobName: [required('Job name is require')],
  beanName: [required('Bean Name is require')],
  cronExpression: [required('Cron is require')],
})

function resetQuery() {
  request.name = null
  request.enable = null
}

async function loadJobs() {
  const { data: ret } = await getQuartzJobs(request)
  page.pages = ret.pages
  page.records = ret.records
}

function changePage(page) {
  request.current = page
  loadJobs()
}

function showDialog(type) {
  if (type === 'EDIT') {
    uiControl.dialogTitle = 'Edit Job'
  } else {
    uiControl.dialogTitle = 'Create Job'
  }
  uiControl.dialogType = type
  uiControl.dialogVisible = true
}

function showCreate() {
  showDialog('CREATE')
  loadAvailableSites()
  nextTick(() => {
    form.id = null
    form.jobName = null
    form.beanName = null
    form.params = null
    form.selectSites = false
    form.enableRecount = false
    form.cronExpression = null
    form.description = null
    form.siteServers = []
    form.state = null

    serverList.list.forEach(server => {
      const s = { serverCode: server.serverCode, sites: [] };
      form.siteServers.push(s);
    });
    form.loadedServers = true;
  })
}

function showEdit(job) {
  showDialog('EDIT')
  loadAvailableSites()
  nextTick(() => {
    form.id = job.id
    form.jobName = job.jobName
    form.beanName = job.beanName
    form.params = job.params
    form.selectSites = job.selectSites
    form.cronExpression = job.cronExpression
    form.description = job.description
    form.siteServers = []
    form.loadedServers = job.loadedServers
    form.state = job.state
    form.enableRecount = job.enableRecount

    const processServers = () => {
      serverList.list.forEach(server => {
        const currServer = job.servers.find(jobServer => jobServer.serverCode === server.serverCode);
        const currSites = currServer ? currServer.sites.split(',').map(Number) : [];
        const s = { serverCode: server.serverCode, sites: currSites };
        form.siteServers.push(s);
      });
    };

    if (!job.loadedServers) {
      setTimeout(async () => {
        const { data: ret } = await getSiteServers(job.id);
        job.servers = ret;
        job.loadedServers = true;
        form.loadedServers = true;
        processServers();
      }, 1000);
    } else {
      processServers();
    }
  })
}
function updateQuartzJob() {
  userForm.value.validate(async valid => {
    if (valid) {
      const request = { ...form }

      if (!request.siteServers) {
        request.siteServers = []
      }

      request.siteServers.forEach(
        siteServer => { siteServer.sites = siteServer.sites ? siteServer.sites.join(',') : '' }
      )

      await updateJobServer(form)
      uiControl.dialogVisible = false
      ElMessage({ message: 'Update Job Success', type: 'success' })
    }
    loadJobs()
  })
}

function loadAvailableSites(serverCode) {
  availableSiteList.list = [];
  const otherSites = [];
  if (serverCode === undefined) {
    availableSiteList.list.push.apply(availableSiteList.list, siteList.list);
  } else {
    form.siteServers.filter(siteServer => siteServer.serverCode !== serverCode).forEach(otherServer => {
      otherSites.push.apply(otherSites, otherServer.sites.slice())
    });
    siteList.list.filter(site => !otherSites.includes(site.id)).forEach(
      site => {
        availableSiteList.list.push(site);
      }
    );
  }
}

function submit() {
  updateQuartzJob()
}

async function loadSites() {
  const { data: ret } = await getSiteListSimple()
  siteList.list = ret
}

async function loadServerStatus() {
  const { data: ret } = await getServers()
  serverList.list = ret
}

function loadJobServers(row) {
  if (!row.loadedServers) {
    setTimeout(async () => {
      const { data: ret } = await getSiteServers(row.id)
      row.servers = ret
      row.loadedServers = true
    }, 1000)
  }
}

function getStatus(row, column, cellValue, index) {
  const server = serverList.list.find(server => server.serverCode === row.serverCode);
  if (!server) {
    return "DOWN"
  } else {
    return server.status
  }
}

function toSiteName(row, column, cellValue, index) {
  let siteIdArr = []
  const siteStrList = []
  if (row.sites) {
    siteIdArr = row.sites.split(',')
    siteIdArr.forEach(element => {
      if (element !== '' && element !== "-1") {
        siteStrList.push(
          siteList.list.find(site => site.id === Number(element)).siteName
        )
      } else if (element === "-1") {
        siteStrList.push('N/A')
      }
    })
    return siteStrList.join(' , ')
  } else {
    return '-'
  }
}

function isRunningOnServer(sites) {
  if (sites.length === 1 && sites[0] === -1) {
    return true;
  }
  return false;
}

function clearFormSites() {
  form.siteServers.forEach(siteServer => {
    siteServer.sites = [];
  });
}

function toggleJobRunOnServer(serverCode) {
  const server = form.siteServers.find(siteServer => siteServer.serverCode === serverCode)
  server.sites = isRunningOnServer(server.sites) ? [] : [-1];
}

onMounted(() => {
  loadSites()
  loadServerStatus()
  loadJobs()
})
</script>

<style rel="stylesheet/scss" lang="scss" scoped>
.header-container {
  margin-bottom: 10px;
}

.search {
  display: flex;
  justify-content: flex-start;
}

.btn-group {
  margin-top: 15px;
}

.dialog-footer {
  display: flex;
  justify-content: flex-end;
}

.el-table--enable-row-transition .el-table__body td.el-table__cell {
  padding: 4px 0;
}
</style>
