<template>
  <div class="menu-main">
    <div class="header-container">
      <div class="btn-group" v-permission="['sys:blacklist:create']">
        <el-button
          icon="el-icon-plus"
          size="mini"
          type="primary"
          @click="uiControl.dialogVisible=true"
        >
          Add
        </el-button>
      </div>
    </div>
    <el-dialog
      title="add Blacklist"
      v-model="uiControl.dialogVisible"
      append-to-body
      width="600px"
      @close="uiControl.popoverVisible = false"
      destroy-on-close
    >
      <el-form
        ref="menuForm"
        :model="form"
        :rules="formRules"
        :inline="true"
        size="small"
        label-width="150px"
      >
        <el-form-item label="Ip" prop="ip">
          <el-input v-model="form.ip" style="width: 300px" />
        </el-form-item>
        <el-form-item
          label="Remark"
          prop="remark"
        >
          <el-select
            clearable
            filterable
            allow-create
            default-first-option
            v-model="form.remark"
            size="small"
            placeholder="agent name"
            class="filter-item"
            style="width: 350px"
          >
            <el-option
              v-for="item in reasons"
              :key="item"
              :label="item"
              :value="item"
            />
          </el-select>
        </el-form-item>
      </el-form>
      <div class="dialog-footer">
        <el-button size="small" @click="uiControl.dialogVisible=false">Cancel</el-button>
        <el-button size="small" @click="submit" type="primary">
          Confirm
        </el-button>
      </div>
    </el-dialog>

    <!-- eslint-disable -->
    <el-table
      :data="list"
      style="width: 100%"
      size="small"
      row-key="id"
      ref="treeTable"
      lazy
      :key="tableKey"
      :cell-class-name="cellGrayWhite"
    >
      <el-table-column
        prop="ip"
        label="ip"
        align="left"
      />
      <el-table-column
        prop="remark"
        label="remark"
        align="left"
      />
      <el-table-column label="operate" align="center">
        <template #default="scope">
          <el-button v-permission="['sys:blacklist:delete']"
                     icon="el-icon-remove"
                     size="mini"
                     type="danger"
                     @click="removeMenu(scope.row)"
          />
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>

<script setup>
import { onMounted, reactive, ref } from 'vue'
import { ElMessage, ElMessageBox } from 'element-plus'
import {
  getBlacklist, addBlacklist, deleteBlacklist
} from '../../../api/site-blacklist'
import { cellGrayWhite } from '@/utils/style'
import { required } from "../../../utils/validate";

const list = reactive([])
const menuForm = ref(null)
const form = reactive({
  ip: null,
  remark: 'no reason',
})
const reasons = ref(['Suspicious Activity', 'Previous Malicious Behavior', 'no reason']);
const formRules = reactive({
  ip: [required('ip is require')],
})
const uiControl = reactive({
  dialogVisible: false,
  popoverVisible: false,
  dialogTitle: '',
  dialogType: 'CREATE',
  switchSettings: ['SEND_MSG_BEFORE_MATCH', 'RATE_CHAT'],
})

async function loadBlacklist() {
  const { data: ret } = await getBlacklist()
  list.length = 0
  list.push(...ret)
}

function submit() {
  menuForm.value.validate(async valid => {
    if (valid) {
      await addBlacklist(form)
      await loadBlacklist()
      uiControl.dialogVisible = false
      ElMessage({ message: 'Add Successful', type: 'success' })
    }
  })
}

function removeMenu(data) {
  ElMessageBox.confirm(`Delete ${data.ip}?`, {
    confirmButtonText: 'Yes',
    cancelButtonText: 'No',
    type: 'warning',
  }).then(async () => {
    await deleteBlacklist(data.id)
    loadBlacklist()
    ElMessage({ message: 'Delete Successful', type: 'success' })
  })
}

onMounted(() => {
  debugger;
  loadBlacklist()
})
</script>

<style rel="stylesheet/scss" lang="scss" scoped>
.header-container {
  margin-bottom: 10px;
}

.search {
  display: flex;
  justify-content: flex-start;
}

.btn-group {
  margin-top: 15px;
}

.dialog-footer {
  display: flex;
  justify-content: flex-end;
}

.el-table--enable-row-transition .el-table__body td.el-table__cell {
  padding: 4px 0;
}

.card-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
</style>
