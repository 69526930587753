<template>
  <div class="header-container">
    <div class="search">
      <el-date-picker
        v-model="request.startDate"
        type="datetime"
        placeholder="Start Date"
        format="YYYY/MM/DD"
        value-format="YYYY-MM-DD HH:mm:ss"
        size="small"
        style="width: 150px;margin:2px"
      />
      <el-date-picker
        v-model="request.endDate"
        type="datetime"
        placeholder="End Date"
        format="YYYY/MM/DD"
        value-format="YYYY-MM-DD HH:mm:ss"
        size="small"
        style="width: 150px;margin:2px"
      />
      <el-input
        v-model="request.guestName"
        size="small"
        style="width: 200px;margin:2px"
        placeholder="user name"
      />
      <el-input
        v-model="request.guestIp"
        size="small"
        style="width: 200px;margin:2px"
        placeholder="ip"
      />
      <el-input
        v-model="request.guestId"
        size="small"
        style="width: 200px;margin:2px"
        placeholder="guest id"
      />
      <el-select
        clearable
        v-model="request.agentName"
        size="small"
        placeholder="agent name"
        class="filter-item"
        style="width: 200px;margin:2px"
      >
        <el-option
          v-for="item in agentList"
          :key="item"
          :label="item"
          :value="item"
        />
      </el-select>
      <el-select
        clearable
        v-model="request.finishType"
        size="small"
        placeholder="finish type"
        class="filter-item"
        style="width: 270px;margin:2px"
      >
        <el-option
          v-for="item in uiControl.finishType"
          :key="item.value"
          :label="item.label"
          :value="item.value"
        />
      </el-select>
      <el-select
        v-model="request.guestRatings"
        multiple
        collapse-tags
        placeholder="Rating"
        size="small"
        style="margin: 2px;"
      >
        <el-option
          v-for="rating in uiControl.ratings"
          :key="rating"
          :value="rating"
          :label="rating"
        >
          <star-rating :rating="rating" :read-only="true" star-size="10" inline="true" />
        </el-option>
      </el-select>
      <treeselect v-model="selectWrapUp" :multiple="true" :options="queryWrapUpList"
                  placeholder="wrapup"
                  style="width: 400px;margin:2px;min-height: 32px;"
      />
      <el-button
        style="margin:2px;height:32px;margin-left: 10px"
        icon="el-icon-search"
        size="mini"
        type="success"
        @click="loadRoom()"
      >
        Search
      </el-button>
      <el-button
        style="margin:2px;height:32px;"
        icon="el-icon-refresh"
        size="mini"
        type="warning"
        @click="resetQuery()"
      >
        reset
      </el-button>
      <el-button
        style="margin:2px;height:32px;"
        icon="el-icon-download"
        size="mini"
        type="success"
        @click="download()"
      />
    </div>
  </div>
  <el-table
    :data="page.records"
    ref="table"
    row-key="id"
    size="mini"
    highlight-current-row
  >
    <el-table-column prop="guestName" label="guestName" width="150px">
      <template #default="scope">
        <div
          style="text-decoration: underline;cursor: pointer;"
          @click="showDetail(scope.row.id)"
        >
          {{ scope.row.guestName }}
        </div>
      </template>
    </el-table-column>
    <el-table-column prop="guestIp" label="Ip" width="150px" />
    <el-table-column prop="country" label="Country" width="150px">
      <template #default="scope">
        {{ scope.row.country + " | " + scope.row.province }}
      </template>
    </el-table-column>
    <el-table-column prop="agentNickNameList" label="Agent" width="100px">
      <template #default="scope">
        <span v-if="scope.row.agentNickNameList.length < 1">-</span>
        <span v-else>
          {{ scope.row.agentNickNameList.join() }}
        </span>
      </template>
    </el-table-column>
    <el-table-column prop="wrapUp" label="Wrap-Up">
      <template #default="scope">
        <div
          :class="{'red': scope.row.wrapUps.length===0}"
          style="text-decoration: underline;cursor: pointer;"
          @click="editorWrapUp(scope.row.id, scope.row.wrapUp)"
        >
          {{
            scope.row.wrapUps.length > 0 ? scope.row.wrapUps.join() : "no wrapUp"
          }}
        </div>
      </template>
    </el-table-column>
    <el-table-column prop="createTime" label="Create Time">
      <template #default="scope">
        <span
          v-formatter="{
            data: scope.row.createTime,
            formatter: 'YYYY/DD/MM HH:mm:ss',
            type: 'date',
          }"
        />
      </template>
    </el-table-column>
    <el-table-column prop="finishType" label="Finish Type">
      <template #default="scope">
        {{ getFinishReason(scope.row.finishType) }}
      </template>
    </el-table-column>
    <el-table-column prop="finishTime" label="Finish Time">
      <template #default="scope">
        <span
          v-formatter="{
            data: scope.row.finishTime,
            formatter: 'YYYY/DD/MM HH:mm:ss',
            type: 'date',
          }"
        />
      </template>
    </el-table-column>
    <el-table-column prop="guestRating" label="Rating" width="150px">
      <template #default="scope">
        <star-rating v-if="scope.row.guestRating" v-model:rating="scope.row.guestRating" :read-only="true"
                     star-size="10" inline="true" :show-rating="false"
        />
      </template>
    </el-table-column>
  </el-table>
  <el-pagination
    class="pagination"
    @current-change="changePage"
    layout="prev, pager, next"
    :page-size="request.size"
    :page-count="page.pages"
    :current-page="request.current"
  />
  <el-drawer
    v-model="drawer"
    title="I am the title"
    :with-header="false"
    :destroy-on-close="true"
    size="50%"
    :open-delay="300"
  >
    <roomDetail :roomId="showRoomId" />
  </el-drawer>

  <el-dialog
    title="Update WrapUp"
    v-model="uiControl.dialogVisible"
    append-to-body
    width="600px"
    destroy-on-close
  >
    <el-form
      ref="menuForm"
      :model="form"
      :inline="true"
      size="small"
      label-width="150px"
    >
      <el-form-item label="WrapUp" style="position: relative; display: flex;align-items: center;justify-content: end;">
        <treeselect v-model="wrapUpForm.wrapUp"
                    :multiple="true"
                    :options="wrapUpList"
                    :disable-branch-nodes="true"
                    :clear-on-select="true"
                    placeholder="wrapup"
                    style="width: 300px;margin:2px;min-height: 32px;z-index: 9999"
        />
      </el-form-item>
    </el-form>
    <div class="dialog-footer">
      <el-button size="small" @click="uiControl.dialogVisible=false">Cancel</el-button>
      <el-button size="small" @click="updateWrapUp" type="primary">
        Confirm
      </el-button>
    </div>
  </el-dialog>
</template>

<script setup>
import { computed, onMounted, reactive, ref } from '@vue/runtime-core'
import {
  downloadRooms,
  roomFinishReason,
  getAgents,
  getSiteRoom,
  getFinishReason,
} from '../../../../api/room'
import { convertDateTime, convertDate, exportExl2 } from '@/utils/util'
import { store } from '../../../../store'
import { map, startsWith } from 'lodash'
import { Treeselect } from 'vue3-treeselect'
import 'vue3-treeselect/dist/vue3-treeselect.css'
import { getWrapUp, updateRoomWrapUp } from "../../../../api/wrap-up";
import StarRating from "vue-star-rating";
import roomDetail from '../details'
import { ElMessage } from "element-plus";

const uiControl = reactive({
  finishType: roomFinishReason,
  ratings: [1, 2, 3, 4, 5],
  dialogVisible: false
})

const drawer = ref(false)
const selectWrapUp = ref(null)
const showRoomId = ref(null)

const userSiteId = computed(() => {
  return store.state.user.siteId
})

const wrapUpForm = reactive({
  id: null,
  wrapUp: null,
})
const request = reactive({
  size: 30,
  current: 1,
  agentName: null,
  finishType: null,
  siteId: userSiteId.value,
  startDate: convertDateTime(new Date().setHours(0, 0, 0, 0)),
  endDate: convertDateTime(new Date().setHours(23, 59, 59, 999)),
  guestName: null,
  guestId: null,
  guestIp: null,
  wrapUp: null,
  guestRatings: [],
})

const page = reactive({
  pages: 0,
  records: [],
})

const agentList = ref([])
const wrapUpList = ref([])
const queryWrapUpList = ref([])

function resetQuery() {
  request.agentName = null
  request.guestName = null
  request.guestId = null
  request.guestIp = null
  request.finishType = null
  request.guestRatings = []
  request.wrapUp = null
  selectWrapUp.value = null
}

function editorWrapUp(id, wrapUp) {
  uiControl.dialogVisible = true;
  wrapUpForm.wrapUp = wrapUp ? wrapUp.split(",") : [];
  wrapUpForm.id = id;
}

async function updateWrapUp() {
  updateRoomWrapUp({ id: wrapUpForm.id, wrapUp: wrapUpForm.wrapUp.join(",") }).then((d) => {
    if (d.code === 0) {
      uiControl.dialogVisible = false;
      ElMessage({ message: 'Update Successful', type: 'success' });
      wrapUpForm.id = null;
      wrapUpForm.wrapUp = null;
      loadRoom();
    }
  })
}

function getWrapupChildren(parentId) {
  let children = parentId;
  wrapUpList.value.forEach(value => {
    if (value.id === parentId && value.children) {
      children = value.children.map((v) => {
        return v.id;
      });
    }
  });
  return children;
}

function changePage(page) {
  request.current = page
  loadRoom()
}

function showDetail(roomId) {
  drawer.value = true
  showRoomId.value = roomId
}

async function download() {
  const downloadRequest = { ...request, size: -1 };
  const { data: list } = await downloadRooms(downloadRequest)
  const json = []
  list.records.forEach((item, index) => {
    const finishTime = convertDateTime(item.finishTime)
    let content = formatMessage(item.message)

    content += `\n\r ( end at ${finishTime})`
    json.push({
      name: item.guestName,
      ip: item.guestIp,
      id: item.guestId,
      agent: item.agentNickNameList.join(),
      wrapup: item.wrapUps.join(),
      matchTime: convertDateTime(item.createTime),
      finishTime: finishTime,
      finishType: getFinishReason(item.finishType),
      rating: item.guestRating,
      message: content,
    })
  })
  const cols = [
    { header: 'name', key: 'name', width: 20 },
    { header: 'ip', key: 'ip', width: 20 },
    { header: 'id', key: 'id', width: 20 },
    { header: 'agent', key: 'agent', width: 20 },
    { header: 'wrapup', key: 'wrapup', width: 30 },
    { header: 'matchTime', key: 'matchTime', width: 30 },
    { header: 'finishTime', key: 'finishTime', width: 20 },
    { header: 'finishType', key: 'finishType', width: 20 },
    { header: 'rating', key: 'rating', width: 5 },
    { header: 'message', key: 'message', width: 20 },
  ]

  const fileName = `${convertDate(request.startDate)}-${convertDate(request.endDate)}.xls`
  exportExl2(json, fileName, cols)
}

function formatMessage(messages) {
  return map(messages, item => {
    if (item.cmd !== 4) {
      return ''
    }

    item = item.body

    let outputStr = ''
    let content = ''

    const name = item.senderName ?? 'sys'

    if (startsWith(item.senderId, 'gt_')) {
      outputStr += `(guest) guest`
    } else {
      outputStr += `(cs) ${name}`
    }

    if (item.ts) {
      outputStr += `[${convertDateTime(new Date(item.ts))}]`
    }

    outputStr += ':'

    if (item.type === 'options') {
      content += '[options]'
    } else if (item.type === 'option_selected') {
      if (item.meta.items && item.meta.items.length) {
        content += item.meta.items[0]?.name
      }
    } else {
      content += item.msg
    }

    if (item.type === 'file') {
      if (item.files && item.files[0]) {
        const file = item.files[0]

        content += `File ${file.url}`
      }
    }

    if (content) {
      outputStr += content
    }

    return outputStr
  }).join('\n\r')
}

async function loadRoom() {
  if (selectWrapUp.value && selectWrapUp.value.length > 0) {
    request.wrapUp = selectWrapUp.value.map((warpupId) => {
      return getWrapupChildren(warpupId);
    }).join(",");
  } else {
    request.wrapUp = null;
  }
  const { data: ret } = await getSiteRoom(request)
  page.pages = ret.pages
  page.records = ret.records
}

async function initData() {
  const { data: ret } = await getAgents({ siteId: userSiteId.value })
  agentList.value = ret

  const { data: menus } = await getWrapUp({ parentId: -1, mode: 1 })
  wrapUpList.value = menus.map((menu) => {
    if (menu.children) {
      return {
        id: menu.id,
        label: menu.name,
        children: menu.children.map((child) => {
          return { id: child.id, label: child.name };
        })
      };
    } else {
      return { id: menu.id, label: menu.name };
    }
  });
  queryWrapUpList.value = wrapUpList.value.slice();
  // add no wrapup
  queryWrapUpList.value.push({ id: -1, label: 'noWrapUp' })
}

onMounted(async () => {
  initData()
  loadRoom()
})
</script>

<style rel="stylesheet/scss" lang="scss" scoped>
.header-container {
  margin-bottom: 10px;
}

.search {
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
}

.btn-group {
  margin-top: 15px;
}

.dialog-footer {
  display: flex;
  justify-content: flex-end;
}

.red {
  color: red;
}

.vue-treeselect__control {
  max-height: 32px;
}
</style>
