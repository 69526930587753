<template>
  <div class="roles-main">
    <div class="header-container" />
    <el-dialog
      :title="uiControl.dialogTitle"
      v-model="uiControl.dialogVisible"
      append-to-body
      width="580px"
    >
      <el-form
        ref="userForm"
        :model="form"
        :rules="formRules"
        :inline="false"
        size="small"
        label-width="150px"
      >
        <el-form-item label="Job" prop="beanName">
          <el-select v-model="form.beanName" placeholder="Select">
            <el-option
              v-for="item in jobList.list"
              :key="item.id"
              :label="item.jobName"
              :value="item.beanName"
            />
          </el-select>
        </el-form-item>
        <el-form-item label="Site" prop="siteId">
          <el-select v-model="form.siteId" placeholder="Select">
            <el-option
              v-for="item in siteList.list"
              :key="item.id"
              :label="item.siteName"
              :value="item.id"
            />
          </el-select>
        </el-form-item>
        <el-form-item
          label="Start time"
          prop="startTime"
        >
          <el-date-picker
            v-model="form.startTime"
            type="datetime"
            placeholder="Pick a day"
            :disabled-date="disabledDate"
            :disabled-minutes="disabledMinutes"
            format="YYYY-MM-DD HH:mm"
            value-format="YYYY-MM-DD HH:mm:ss"
          />
        </el-form-item>
        <el-form-item
          label="End Time"
          prop="endTime"
        >
          <el-date-picker
            v-model="form.endTime"
            type="datetime"
            placeholder="Pick a day"
            :disabled-date="disabledDate"
            :disabled-minutes="disabledMinutes"
            format="YYYY-MM-DD HH:mm"
            value-format="YYYY-MM-DD HH:mm:ss"
          />
        </el-form-item>
        <div class="dialog-footer">
          <el-button @click="uiControl.dialogVisible = false">cancel</el-button>
          <el-button type="primary" @click="submit">confirm</el-button>
        </div>
      </el-form>
    </el-dialog>
    <el-button
      icon="el-icon-plus"
      size="mini"
      type="primary"
      @click="showCreate"
    >
      Add
    </el-button>
    <el-table
      :data="page.records"
      ref="table"
      row-key="id"
      size="mini"
      highlight-current-row
      :cell-class-name="cellGrayWhite"
    >
      <el-table-column label="Job" prop="jobName" />
      <el-table-column label="Site" prop="site" />
      <el-table-column label="Start" prop="startTime" :min-width="100" />
      <el-table-column label="End" prop="endTime" :min-width="100" />
      <el-table-column label="Created At" prop="createTime" :min-width="100" />
      <el-table-column label="Finished At" prop="finishTime" :min-width="100" />
      <el-table-column label="Status">
        <template #default="scope">
          <el-tag v-if="scope.row.status === 'WATTING'" type="warning">
            WAITING
          </el-tag>
          <el-tag v-if="scope.row.status === 'RUNNING'">
            RUNNING
          </el-tag>
          <el-tag v-if="scope.row.status === 'COMPLETE'" type="success">
            COMPLETE
          </el-tag>
          <el-tag v-if="scope.row.status === 'FAILURE'" type="danger">
            FAILED
          </el-tag>
          <el-tag v-if="scope.row.status === 'EXPIRED'" type="danger">
            EXPIRED
          </el-tag>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      class="pagination"
      @current-change="changePage"
      layout="prev, pager, next"
      :page-size="request.size"
      :page-count="page.pages"
      :current-page="request.current"
    />
  </div>
</template>

<script setup>
import { onMounted, reactive, ref } from 'vue'
import { required } from '../../../utils/validate'
import { ElMessage } from 'element-plus'
import {
  getAvailableJobs,
  getRecountJobs,
  addRecountJob
} from '../../../api/recount-job'
import { cellGrayWhite } from '@/utils/style'
import { getSiteListSimple } from '../../../api/site'

const siteList = reactive({
  list: [],
})

const jobList = reactive({
  list: [],
})

const userForm = ref(null)
const uiControl = reactive({
  dialogVisible: false,
  dialogTitle: '',
  dialogType: 'EDIT',
  // editBtn: true,
})
const page = reactive({
  pages: 0,
  records: [],
})
const request = reactive({
  size: 30,
  current: 1,
  name: null,
  enable: null,
})

const form = reactive({
  id: null,
  beanName: null,
  siteId: null,
  startTime: null,
  endTime: null,
})

const formRules = reactive({
  beanName: [required('Job is require')],
  siteId: [required('Site is require')],
  startTime: [required('startTime is require')],
  endTime: [{ required: true, message: ('endTime is require') }, { validator: checkEndTime, trigger: 'change' }],
})

async function loadRecountJobs() {
  const { data: ret } = await getRecountJobs(request)
  page.pages = ret.pages
  page.records = ret.records
}

function changePage(page) {
  request.current = page
  loadRecountJobs()
}

function showDialog(type) {
  if (type === 'CREATE') {
    uiControl.dialogTitle = 'Create Job'
  }
  uiControl.dialogType = type
  uiControl.dialogVisible = true
}

function showCreate(user) {
  showDialog('CREATE')
  form.id = null;
  form.beanName = null;
  form.siteId = null;
  form.startTime = null;
  form.endTime = null;
}
function createRecountJob() {
  userForm.value.validate(async valid => {
    if (valid) {
      await addRecountJob(form)
      uiControl.dialogVisible = false
      ElMessage({ message: 'Update Dialog Max Success', type: 'success' })
    }
    await loadRecountJobs()
  })
}

function submit() {
  createRecountJob()
}

async function loadSites() {
  const { data: ret } = await getSiteListSimple()
  siteList.list = ret
  siteList.list.push({ id: -1, siteName: 'N/A' })
}

async function loadJobs() {
  const { data: ret } = await getAvailableJobs()
  jobList.list = ret
}

function disabledDate(date) {
  if (date.getTime() > Date.now()) {
    return true;
  }
  return false;
}

function disabledMinutes(hour) {
  const makeRange = (start, end) => {
    const result = []
    for (let i = start; i <= end; i++) {
      result.push(i)
    }
    return result
  }
  const fullMin = makeRange(0, 59)
  return fullMin.filter(min => min !== 0 && min !== 30)
}

function checkEndTime(rule, value, callback) {
  if (value <= form.startTime) {
    callback(new Error('endTime must be greater than startTime'))
  } else {
    callback()
  }
}

onMounted(() => {
  loadSites()
  loadJobs()
  loadRecountJobs()
})
</script>

<style rel="stylesheet/scss" lang="scss" scoped>
.header-container {
  margin-bottom: 10px;
}

.search {
  display: flex;
  justify-content: flex-start;
}

.btn-group {
  margin-top: 15px;
}

.dialog-footer {
  display: flex;
  justify-content: flex-end;
}

.el-table--enable-row-transition .el-table__body td.el-table__cell {
  padding: 4px 0;
}
</style>

<style rel="stylesheet/scss" lang="scss" >
li.el-time-spinner__item.disabled {
  display: none;
}
</style>
