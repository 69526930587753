<template>
  <div class="roles-main">
    <div class="header-container">
      <div class="search">
        <el-input
          v-model="request.name"
          size="small"
          style="width: 200px"
          placeholder="Roles name"
        />
        <el-select
          clearable
          :disabled="true"
          v-model="request.siteId"
          size="small"
          placeholder="Site"
          class="filter-item"
          style="width: 120px; margin-left: 5px"
        >
          <el-option
            v-for="item in userSites"
            :key="item.id"
            :label="item.name"
            :value="item.id"
          />
        </el-select>
        <el-button
          style="margin-left: 20px"
          icon="el-icon-search"
          size="mini"
          type="success"
          @click="loadData()"
        >
          Search
        </el-button>
        <el-button
          icon="el-icon-refresh"
          size="mini"
          type="warning"
          @click="resetQuery()"
        >
          Reset
        </el-button>
      </div>
      <div class="btn-group">
        <el-button
          icon="el-icon-plus"
          size="mini"
          type="primary"
          v-permission="['sys:roles:create']"
          @click="showDialog('CREATE')"
        >
          Add
        </el-button>
        <el-button
          icon="el-icon-edit"
          size="mini"
          type="success"
          v-permission="['sys:roles:update']"
          @click="showEdit"
          :disabled="!uiControl.editBtn"
        >
          Update
        </el-button>
        <el-button
          icon="el-icon-remove"
          size="mini"
          type="danger"
          v-permission="['sys:roles:delete']"
          @click="removeBatchRole"
          :disabled="!uiControl.removeBtn"
        >
          Delete
        </el-button>
      </div>
    </div>
    <el-dialog
      :title="uiControl.dialogTitle"
      v-model="uiControl.dialogVisible"
      append-to-body
      width="600px"
    >
      <el-form
        ref="rolesForm"
        :model="form"
        :rules="formRules"
        :inline="true"
        size="small"
        label-width="100px"
      >
        <el-form-item label="Role Name" prop="name">
          <el-input v-model="form.name" style="width: 450px" />
        </el-form-item>
        <el-form-item label="Site" prop="siteId">
          <el-select
            v-model="form.siteId"
            size="small"
            placeholder="Site"
            class="filter-item"
            style="width: 450px"
            default-first-option
            :disabled="true"
          >
            <el-option
              v-for="item in userSites"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            />
          </el-select>
        </el-form-item>
        <el-form-item label="describe" prop="remark">
          <el-input
            type="textarea"
            :rows="5"
            v-model="form.remark"
            style="width: 450px"
            placeholder="describe"
          />
        </el-form-item>
        <div class="dialog-footer">
          <el-button @click="uiControl.dialogVisible = false">Cancel</el-button>
          <el-button type="primary" @click="submit">Confirm</el-button>
        </div>
      </el-form>
    </el-dialog>
    <div class="body-container">
      <el-card class="roles" shadow="never">
        <template #header>
          <div class="card-header">
            <span>Role List</span>
          </div>
        </template>
        <el-table
          :data="page.records"
          ref="table"
          row-key="id"
          size="small"
          highlight-current-row
          @current-change="selectRoles"
          :cell-class-name="cellGrayWhite"
        >
          <el-table-column width="55">
            <template #default="scope">
              <el-radio v-model="radioChecked" :label="scope.row.id" />
            </template>
          </el-table-column>
          <el-table-column prop="name" label="Role Name" width="200" />
          <el-table-column prop="siteName" label="Site Name" width="200" />
          <el-table-column prop="remark" label="describe" width="200" />
          <el-table-column prop="createTime" label="Create By" width="200" />
          <el-table-column prop="createBy" label="Create Time" />
          <el-table-column
            label="Operate"
            align="right"
            v-if="
              hasPermission(['sys:roles:update']) ||
                hasPermission(['sys:roles:delete'])
            "
          >
            <template #default="scope">
              <el-button
                icon="el-icon-edit"
                size="mini"
                type="success"
                v-permission="['sys:roles:update']"
                @click="showEdit(scope.row)"
              />
              <el-button
                icon="el-icon-remove"
                size="mini"
                type="danger"
                v-permission="['sys:roles:delete']"
                @click="remove(scope.row)"
              />
            </template>
          </el-table-column>
        </el-table>
        <el-pagination
          class="pagination"
          @current-change="changePage"
          layout="prev, pager, next"
          :page-size="request.size"
          :page-count="page.pages"
          :current-page="request.current"
        />
      </el-card>
      <el-card class="menu" shadow="never">
        <template #header>
          <div class="card-header">
            <span>Permission Assignment</span>
            <el-button
              size="mini"
              :disabled="!uiControl.updatePermissionBtn"
              type="primary"
              v-permission="['sys:roles:update:permission']"
              @click="updatePermission"
            >
              Assignment
            </el-button>
          </div>
        </template>
        <el-tree
          ref="tree"
          show-checkbox
          accordion
          node-key="id"
          :data="menus.list"
          :props="{
            id: 'id',
            label: 'name',
            children: 'children',
          }"
          highlight-current
        />
      </el-card>
    </div>
  </div>
</template>

<script setup>
import { computed, nextTick, onMounted, reactive, ref } from 'vue'
import { required } from '../../../utils/validate'
import { ElMessage, ElMessageBox } from 'element-plus'
import {
  createRole,
  delBatchRoles,
  delRoles,
  getRoles,
  updateRole,
  updateRolePermission,
} from '../../../api/roles'
import { fetchSimpleMenu } from '../../../api/menus'
import { hasPermission } from '../../../utils/util'
import { store } from '../../../store'
import { cellGrayWhite } from '@/utils/style'
import { getSiteListSimple } from '../../../api/site'

const userSiteId = computed(() => {
  return store.state.user.siteId
})

const userSites = computed(() => {
  return store.state.user.sites
})

const uiControl = reactive({
  dialogVisible: false,
  dialogTitle: '',
  dialogType: 'CREATE',
  updatePermissionBtn: false,
  editBtn: false,
  removeBtn: false,
})

const siteList = reactive({ list: [] })

const rolesForm = ref(null)
const tree = ref(null)
const table = ref(null)
let selectRolesId = 0
const radioChecked = ref(null)
let currentSelectItem = reactive({})
const rolesID = []
const formRules = reactive({
  name: [required('Role Name is require')],
})

const form = reactive({
  id: null,
  name: null,
  siteId: null,
  remark: null,
})

const page = reactive({
  pages: 0,
  records: [],
})

const request = reactive({
  siteId: userSiteId.value,
  size: 30,
  current: 1,
})

const menus = reactive({ list: [] })

function showDialog(type) {
  if (type === 'CREATE') {
    if (rolesForm.value) {
      rolesForm.value.resetFields()
    }
    form.siteId = userSiteId.value
    uiControl.dialogTitle = 'Add Role'
  } else {
    uiControl.dialogTitle = 'Edit Role'
  }
  uiControl.dialogType = type
  uiControl.dialogVisible = true
}

async function loadData() {
  const { data: ret } = await getRoles(request)
  page.pages = ret.pages
  ret.records.forEach(item => {
    item.radioChecked = false
  })
  page.records = ret.records
}

/**
 * 新增校色
 */
function create() {
  rolesForm.value.validate(async valid => {
    if (valid) {
      await createRole(form)
      uiControl.dialogVisible = false
      await loadData()
      ElMessage({ message: 'Add Success', type: 'success' })
    }
  })
}

function showEdit(roles) {
  showDialog('EDIT')
  if (!roles.id) {
    roles = currentSelectItem
  }
  nextTick(() => {
    for (const key in roles) {
      if (Object.keys(form).find(k => k === key)) {
        form[key] = roles[key]
      }
    }
    console.log(siteList.list)
    const site = siteList.list.find(x => x.siteName === roles.siteName)
    form.siteId = site.id
  })
}

/**
 * 编辑角色
 */
function edit() {
  rolesForm.value.validate(async valid => {
    if (valid) {
      await updateRole(form)
      uiControl.dialogVisible = false
      await loadData()
      ElMessage({ message: 'Edit Success', type: 'success' })
    }
  })
}

/**
 * 删除校色
 * @param roles
 */
function remove(roles) {
  ElMessageBox.confirm('Delete Role', {
    confirmButtonText: 'confirm',
    cancelButtonText: 'cancel',
    type: 'warning',
  }).then(async () => {
    await delRoles(roles.id)
    await loadData()
    uiControl.updatePermissionBtn = true
    ElMessage({ message: 'Delete Success', type: 'success' })
  })
}

function changePage(page) {
  request.current = page
  loadData()
}

function submit() {
  if (uiControl.dialogType === 'CREATE') {
    create()
  } else {
    edit()
  }
}

async function loadTreeMenu() {
  const { data: children } = await fetchSimpleMenu()
  menus.list = children
}

function selectRoles(val) {
  radioChecked.value = val.id
  currentSelectItem = val
  selectRolesId = val.id
  tree.value.setCheckedKeys([], false);
  val.menus.forEach(e => {
    const node = tree.value.getNode(e);
    if (node && node.isLeaf) {
      tree.value.setChecked(e, true);
    }
  })
  table.value.clearSelection()
  table.value.toggleRowSelection(val)
  uiControl.updatePermissionBtn = true
  uiControl.editBtn = true
  uiControl.removeBtn = true
}

async function updatePermission() {
  const selectedMenus = tree.value.getHalfCheckedKeys().concat(tree.value.getCheckedKeys())
  await updateRolePermission({ id: selectRolesId, menuIds: selectedMenus })
  await loadData()
  ElMessage({ message: 'Update Permission Success', type: 'success' })
}

async function removeBatchRole() {
  ElMessageBox.confirm(
    `Confirm that you want to delete these ${rolesID.length} data, the change operation cannot be undone`,
    {
      confirmButtonText: 'confirm',
      cancelButtonText: 'cancel',
      type: 'warning',
    }
  ).then(async () => {
    await delBatchRoles(rolesID.map(r => r.id))
    await loadData()
    uiControl.updatePermissionBtn = true
    ElMessage({ message: 'Delete Success', type: 'success' })
  })
}

async function loadSites() {
  const { data: ret } = await getSiteListSimple()
  siteList.list = ret
}

onMounted(() => {
  loadSites()
  loadData()
  loadTreeMenu()
})
</script>

<style rel="stylesheet/scss" lang="scss" scoped>
.btn-group {
  margin-top: 15px;
}

.body-container {
  margin-top: 20px;
  width: 100%;
  display: flex;
  justify-content: space-between;

  .roles {
    width: 75%;

    .pagination {
      margin-top: 10px;
    }
  }

  .menu {
    width: 24%;

    .card-header {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
  }
}

.dialog-footer {
  display: flex;
  justify-content: flex-end;
}

.el-card__header {
  padding-bottom: 13px;
}

.el-table--enable-row-transition .el-table__body td.el-table__cell {
  padding: 4px 0;
}
</style>

<style rel="stylesheet/scss" lang="scss">
.el-radio__label {
  display: none;
}
</style>
