import https from "@/utils/https";
import { ContentType, Method } from "axios-mapper";

export const ButtonType = {
  API: "API", SEND_LOGIN_NAME: "SEND_LOGIN_NAME", LOGIN_PASSWORD: "LOGIN_PASSWORD", LOGIN_PAGE: "LOGIN_PAGE"
}

export const fetchButton = (request) => {
  return https().request("/site-api-button", Method.GET, request);
};

export const createSiteApiButton = (data) => {
  return https().request("/site-api-button", Method.POST, data, ContentType.form);
};

export const updateSiteApiButton = (data) => {
  return https().request(`/site-api-button/${data.id}?_method=PUT`, Method.POST, data, ContentType.form);
};

export const deleteSiteApiButton = (siteApiId) => {
  return https().request(`/site-api-button/${siteApiId}?_method=DELETE`, Method.POST);
};
