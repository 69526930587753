import { createRouter, createWebHistory } from "vue-router";
import Layout from "@/layout/Index.vue";

export const constantRoutes = [
  {
    path: "/login",
    name: "Login",
    meta: { hidden: true },
    component: () => import(/* webpackChunkName: "login" */"../views/login/index.vue")
  },
  {
    path: "/",
    component: Layout,
    redirect: "/dashboard",
    children: [
      {
        path: "dashboard",
        component: () => import(/* webpackChunkName: "dashboard" */ "../views/home/index.vue"),
        name: "Dashboard",
        meta: {
          title: "dashboard",
          icon: "index"
        }
      }
    ]
  },
  {
    path: "/",
    component: Layout,
    redirect: "/webClient",
    children: [
      {
        path: "webClient",
        component: () => import(/* webpackChunkName: "dashboard" */ "../views/webClient/index.vue"),
        name: "WebClient",
        meta: {
          title: "webClient",
          icon: "wechat"
        }
      }
    ]
  },
  {
    path: '/error',
    component: Layout,
    name: 'Error Pages',
    redirect: 'noredirect',
    meta: {
      title: 'errorPages',
      hidden: true,
      icon: '#icon404'
    },
    children: [
      {
        path: '401',
        component: () => import(/* webpackChunkName: "error-page-401" */ '@/views/error-page/401.vue'),
        name: 'Page401',
        meta: {
          title: 'page401',
          noCache: true
        }
      },
      {
        path: '404',
        component: () => import(/* webpackChunkName: "error-page-404" */ '@/views/error-page/404.vue'),
        name: 'Page404',
        meta: {
          title: 'page404',
          noCache: true
        }
      }
    ]
  }
];

export const notFound = {
  path: '/:pathMatch(.*)*',
  redirect: '/error/404',
  meta: { hidden: true }
}

const router = createRouter({
  history: createWebHistory(),
  routes: constantRoutes
});

export function resetRouter() {
  const newRouter = router;
  router.matcher = newRouter.matcher; // reset router
}

export default router;
